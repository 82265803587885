import { MapSelector } from "./mapSelector";
import { Demographics } from "./demographics";
import { NeighbourhoodMap } from "./neighbourhoodMap";
import type { Listing } from "../../../interfaces/listing";
import { useRef, useState, type KeyboardEvent } from "react";
import GrayArrowDownIcon from "../../../assets/grayArrowDownIcon.svg";
import type { StatisticsResponse } from "../../../interfaces/statistics";
import NeighbourhoodInformationIcon from "../../../assets/Listing/neighbourhoodInformationIcon.svg";

interface NeighbourhoodInformationProps {
	listing: Listing;
	statistics?: StatisticsResponse | null;
}

const NeighbourhoodInformation = ({
	listing,
	statistics,
}: NeighbourhoodInformationProps) => {
	const [isExpanded, setIsExpanded] = useState(true);
	const sectionRef = useRef<HTMLDivElement>(null);
	const [nearbyType, setNearbyType] = useState<string>("school");

	const toggleSection = () => {
		setIsExpanded((prevState) => !prevState);
	};

	const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
		if (e.key === "Enter" || e.key === " ") {
			e.preventDefault();
			toggleSection();
		}
	};

	return (
		<div className="w-full bg-white flex justify-between rounded-[20px] py-4 items-start shadow-md drop-shadow-lg">
			<div className="w-full">
				<div
					className="text-black font-semibold text-xl flex items-center justify-between w-full cursor-pointer px-4"
					onClick={toggleSection}
					onKeyDown={handleKeyDown}
					aria-expanded={isExpanded}
					aria-controls="neighbourhood-content"
				>
					<div className="flex items-center gap-2">
						<img src={NeighbourhoodInformationIcon} alt="" />
						Neighbourhood Information
					</div>

					<img
						src={GrayArrowDownIcon}
						alt="Toggle Section"
						className={`transition-transform duration-300 ${
							isExpanded ? "rotate-180" : ""
						}`}
					/>
				</div>

				<div
					id="neighbourhood-map"
					className={`overflow-hidden transition-[max-height] duration-300 ease-in-out space-y-6 ${
						isExpanded ? "max-h-auto" : "max-h-0"
					}`}
				>
					{statistics?.googlePlaces && (
						<>
							<hr className="h-px bg-[#F3F3F3] border-0 mt-6" />

							<div className="space-y-4">
								<h2 className="font-semibold px-4 text-lg">{`What's ${
									listing.community ? `in ${listing.community}` : "Nearby"
								}`}</h2>
								<h3 className="text-sm px-4 text-gray-700">{`See what's located around the neighbourhood`}</h3>
								<div>
									<MapSelector
										nearbyType={nearbyType}
										setNearbyType={setNearbyType}
									/>

									<NeighbourhoodMap
										listing={listing}
										nearbyType={nearbyType}
										googlePlaces={statistics.googlePlaces}
									/>
								</div>
							</div>
						</>
					)}

					<div
						id="neighbourhood-content"
						ref={sectionRef}
						className="space-y-6 overflow-hidden transition-max-height duration-300 ease-in-out"
					>
						<hr className="h-px bg-[#F3F3F3] border-0 mt-6 mx-4" />

						<Demographics listingId={listing.listingId} />
					</div>
				</div>
			</div>
		</div>
	);
};

export { NeighbourhoodInformation };
