import { Bridge } from "../bridge.mts";
import { fetchAccountData } from "../services/account";
import { storeFCMToken } from "../services/notifications";
import { useUserProfileStore } from "../stores/profileStore";
import { LoadingCircle } from "../components/common/loading";
import {
	type ReactNode,
	createContext,
	useCallback,
	useContext,
	useEffect,
	useState,
} from "react";

interface AuthContextProps {
	email: string;
	setEmail: (email: string) => void;
	authenticated: boolean;
	setAuthenticated: (authenticated: boolean) => void;
	isWebview: boolean;
	setIsWebview: (isWebview: boolean) => void;
}

interface ErrorWithResponse extends Error {
	response?: {
		status: number;
	};
}

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
	const [email, setEmail] = useState<string>("");
	const [authenticated, setAuthenticated] = useState<boolean>(false);
	const [isWebview, setIsWebview] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const { userProfile, setUserProfile } = useUserProfileStore();

	const checkVersion = useCallback(async () => {
		try {
			setLoading(true);
			let version = null;
			try {
				version = await Bridge.getBridgeVersion();
				console.log("version", version);
			} catch (error) {
				console.error("Error fetching version:", error);
				version = null;
			}

			if (version) {
				setIsWebview(false);
				return false;
			}
			setIsWebview(true);
			return true;
		} catch (generalError) {
			console.error("An unexpected error occurred:", generalError);
			setIsWebview(true);
			return true;
		} finally {
			setLoading(false);
		}
	}, []);

	useEffect(() => {
		const checkAuth = async () => {
			try {
				const isWebviewDetected = await checkVersion();

				if (isWebviewDetected) {
					setAuthenticated(true);
				} else {
					setLoading(true);

					const accountData = await fetchAccountData();

					if (accountData) {
						setUserProfile(accountData);
						setAuthenticated(true);
					} else {
						setAuthenticated(false);
					}

					setLoading(false);
				}
			} catch (error) {
				const err = error as ErrorWithResponse;
				setAuthenticated(false);

				if (err.response && err.response.status === 401) {
					setAuthenticated(false);
				} else {
					console.error("Failed to check authentication", error);
				}
				setLoading(false);
			}
		};

		checkAuth();
	}, [setUserProfile, checkVersion]);

	useEffect(() => {
		const fetchTokenAndStatus = async () => {
			try {
				const token = await Bridge.getFCMToken();
				if (typeof token === "string") {
					await storeFCMToken(token);
				} else {
					console.warn("FCM Token not retrieved");
				}
			} catch (error) {
				console.error("Error fetching FCM Token or Notification Status", error);
			}
		};

		fetchTokenAndStatus();
	}, []);

	// useEffect(() => {
	// if (userProfile) {
	// 	setAuthenticated(true);
	// }
	// if (authenticated) {
	// 	console.log("User is authenticated");
	// 	console.log("User Profile", userProfile);
	// } else {
	// 	console.log("User is not authenticated");
	// 	console.log("User Profile", userProfile);
	// }
	// }, [authenticated, userProfile]);

	return (
		<AuthContext.Provider
			value={{
				email,
				setEmail,
				authenticated,
				setAuthenticated,
				isWebview,
				setIsWebview,
			}}
		>
			{loading ? (
				<div className="absolute w-full h-full flex items-center justify-center bg-[#F9FBFF]">
					<LoadingCircle />
				</div>
			) : (
				children
			)}
		</AuthContext.Provider>
	);
};

export const useAuth = (): AuthContextProps => {
	const context = useContext(AuthContext);
	if (!context) {
		throw new Error("useAuth must be used within an AuthProvider");
	}
	return context;
};
