import { LoadingCircle } from "../common";
import { PropertyCard } from "./propertyCard";
import { useNavigate } from "react-router-dom";
import HeartIcon from "../../assets/heartIcon.svg";
import { SortOptions } from "../common/sortOptions";
import { fetchAccountData } from "../../services/account";
import CheckIcon from "../../assets/checkShowingsIcon.svg";
import { useUserProfileStore } from "../../stores/profileStore";
import { useBottomBarStore } from "../../stores/bottomBarStore";
import { useState, useRef, useEffect, useCallback } from "react";
import { useShowingCartStore } from "../../stores/showingCartStore";
import { BuyerOutingStatus } from "../../interfaces/outingInterface";
import type { CartProperties } from "../../interfaces/cartProperties";
import NoMorePropertiesIcon from "../../assets/noMorePropertiesIcon.svg";
import GetPreApprovedIcon from "../../assets/Cart/getPreApprovedIcon.svg";
import { fetchCartProperties, fetchOuting } from "../../services/outings";
import ArrowRightShowingIcon from "../../assets/arrowRightShowingIcon.svg";
import GetPreApprovedCardIcon from "../../assets/Cart/getPreApprovedButtonIcon.svg";

const CartView = () => {
	const navigate = useNavigate();
	const { setCartProperties, cartProperties } = useShowingCartStore();
	const { userProfile, setUserProfile } = useUserProfileStore();
	const [loading, setLoading] = useState(false);
	const { bottomBarHeight } = useBottomBarStore();
	const [showOptions, setShowOptions] = useState(false);
	const [preApproved, setPreApproved] = useState(false);
	const optionsRef = useRef<HTMLDivElement | null>(null);
	const [selectedOption, setSelectedOption] = useState("Default");
	const [hasShowings, setHasShowings] = useState(false);

	useEffect(() => {
		if (userProfile?.finances?.preApprovals) {
			setPreApproved(true);
		}
	}, [userProfile]);

	const fetchCartData = useCallback(async () => {
		try {
			setLoading(true);
			const [outingResult, cartPropertiesResult] = await Promise.all([
				fetchOuting(),
				fetchCartProperties(),
			]);

			setCartProperties(cartPropertiesResult);
			const outings = outingResult || [];

			const hasPendingShowings = outings.some(
				(outing) =>
					outing.status === BuyerOutingStatus.Confirmed ||
					outing.status === BuyerOutingStatus.WaitingForConfirmation,
			);

			setHasShowings(hasPendingShowings);

			setLoading(false);
		} catch (error) {
			console.error("Failed to fetchCartData", error);
			setLoading(false);
		}
	}, [setCartProperties]);

	useEffect(() => {
		fetchCartData();
	}, [fetchCartData]);

	const toggleOptions = () => {
		setShowOptions(!showOptions);
	};

	const handleRemoveProperty = async () => {
		try {
			setLoading(true);
			const response = await fetchCartProperties();
			setCartProperties(response);
			setLoading(false);
		} catch (error) {
			console.error("Failed to fetchCartData", error);
			setLoading(false);
		}
	};

	useEffect(() => {
		if (showOptions && optionsRef.current) {
			const optionsRect = optionsRef.current.getBoundingClientRect();
			const isOffScreen = optionsRect.right > window.innerWidth;

			if (isOffScreen) {
				optionsRef.current.style.left = `-${
					optionsRect.right - window.innerWidth + 20
				}px`;
			}
		}
	}, [showOptions]);

	const sortByNewest = (a: CartProperties, b: CartProperties) =>
		b.listDate.getTime() - a.listDate.getTime();
	const sortByOldest = (a: CartProperties, b: CartProperties) =>
		a.listDate.getTime() - b.listDate.getTime();
	const sortByLowestPrice = (a: CartProperties, b: CartProperties) =>
		a.listPrice.amount - b.listPrice.amount;
	const sortByHighestPrice = (a: CartProperties, b: CartProperties) =>
		b.listPrice.amount - a.listPrice.amount;

	const sortedProperties = [...cartProperties].sort((a, b) => {
		switch (selectedOption) {
			case "Newest": {
				return sortByNewest(a, b);
			}
			case "Oldest": {
				return sortByOldest(a, b);
			}
			case "Price Low to High": {
				return sortByLowestPrice(a, b);
			}
			case "Price High to Low": {
				return sortByHighestPrice(a, b);
			}
			default: {
				return 0;
			}
		}
	});

	const handleBookShowingClick = () => {
		window
			._sendEvent("ZownAppBuyerBookShowing", { Step: 1 })
			.catch((error: unknown) => {
				console.error(error);
			})
			.finally(() => {
				navigate("/cart/checkout-cart-properties");
			});
	};

	const handleLikedHomesClick = async () => {
		try {
			setLoading(true);
			const accountData = await fetchAccountData();

			if (accountData) {
				setUserProfile(accountData);
			}

			setLoading(false);
			navigate("/profile/liked-homes");
		} catch (error) {
			console.error("Failed to getProfile", error);
			navigate("/profile/liked-homes");
		}
	};

	return (
		<div
			className={`w-full text-black ${
				loading || cartProperties.length === 0 ? "h-full" : ""
			}  space-y-4 pt-5 pb-6`}
		>
			{loading && (
				<div className="h-full flex justify-center items-center">
					<LoadingCircle />
				</div>
			)}
			{!loading && cartProperties.length === 0 && (
				<div className="flex items-center text-center justify-center flex-col h-full w-full px-4 space-y-8">
					<div className="space-y-3">
						<div className="font-bold text-3xl">Oops !</div>
						<div className="text-2xl">
							no <span className="text-[#FF5757]">properties</span> in your cart
						</div>
					</div>
					<img src={NoMorePropertiesIcon} alt="No More Properties" />
					<div className="w-full space-y-1">
						<div className="text-xl">add properties to your cart</div>
						<button
							type="button"
							className="bg-z-dark-moss-green text-white font-bold text-xl py-3.5 w-full rounded-full"
							onClick={handleLikedHomesClick}
						>
							My liked homes
						</button>
					</div>
				</div>
			)}
			{!loading && cartProperties.length !== 0 && (
				<div
					style={{ paddingBottom: `${preApproved ? bottomBarHeight : 0}px` }}
					className="space-y-4"
				>
					{!hasShowings && preApproved && (
						<div className="px-2">
							<div className="rounded-[11px] bg-[#EAFFDE] p-4 h-[79px] flex justify-start items-center gap-3">
								<img src={HeartIcon} alt="Heart Icon" />
								<div>
									<div className="font-bold">Pick your showings</div>
									<div className="text-xs">
										Select your top 8 properties to go on showings
									</div>
								</div>
							</div>
						</div>
					)}
					{hasShowings && (
						<div className="px-2 w-full">
							<div
								className="rounded-[11px] w-full bg-[#0A9D57]/10 p-4 h-[79px] flex justify-between items-center gap-3"
								onClick={() => navigate("/profile/showing")}
								onKeyDown={(e) => {
									if (e.key === "Enter" || e.key === " ") {
										navigate("/profile/showing");
									}
								}}
							>
								<div className="flex items-center gap-2">
									<img src={CheckIcon} alt="Heart Icon" className="w-[33px]" />
									<div>
										<div className="font-bold">Your Showings Are Booked!</div>
										<div className="text-xs">
											Click here to see your upcoming showing
										</div>
									</div>
								</div>
								<button type="button">
									<img src={ArrowRightShowingIcon} alt="" />
								</button>
							</div>
						</div>
					)}
					{!preApproved && (
						<div className="px-2">
							<div
								className="rounded-[11px] bg-[#EDF5FF] p-4 h-[79px] flex justify-between items-center"
								onClick={() => navigate("/profile/get-preapproved")}
								onKeyDown={(e) => {
									if (e.key === "Enter" || e.key === " ") {
										navigate("/profile/get-preapproved");
									}
								}}
							>
								<div className="flex gap-3">
									<img src={GetPreApprovedIcon} alt="Heart Icon" />
									<div>
										<div className="font-bold">Get pre-approved</div>
										<div className="text-xs">
											To book showings you need a pre-approval
										</div>
									</div>
								</div>
								<button type="button">
									<img src={GetPreApprovedCardIcon} alt="" />
								</button>
							</div>
						</div>
					)}
					<div className="flex justify-between w-full items-center px-2">
						<div className="font-semibold px-2">Showings Cart</div>
						<div className="font-semibold text-sm">
							<SortOptions
								showOptions={showOptions}
								optionsRef={optionsRef}
								onToggleOptions={toggleOptions}
								selectedOption={selectedOption}
								setSelectedOption={setSelectedOption}
							/>
						</div>
					</div>

					{sortedProperties.map((property, index) => (
						<div key={property.listingId} className="space-y-6">
							<PropertyCard
								property={property}
								onRemoveProperty={handleRemoveProperty}
							/>
							{index !== sortedProperties.length - 1 && <hr />}
						</div>
					))}

					{!hasShowings && preApproved && (
						<div
							className="px-2 fixed w-full"
							style={{
								bottom: `${bottomBarHeight + (window.bridge?.adjustedContentInset?.bottom || 10)}px`,
							}}
						>
							<button
								type="button"
								className="bg-z-dark-moss-green w-full font-semibold text-[20px] rounded-full text-white py-3.5 shadow-md drop-shadow-md flex items-center justify-center gap-3"
								onClick={handleBookShowingClick}
							>
								Book showing
							</button>
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export { CartView };
