import { create } from "zustand";

interface ShowLoginModalStoreState {
  showLoginModal: boolean;
  setShowLoginModal: (show: boolean) => void;
}

const useShowLoginModalStore = create<ShowLoginModalStoreState>((set) => ({
  showLoginModal: false,
  setShowLoginModal: (show) => set({ showLoginModal: show }),
}));

export { useShowLoginModalStore };
