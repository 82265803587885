import { nanoid } from "nanoid";
import { Button, Select } from "../common";
import schoolJson from "../../schools.json";
import { useNavigate } from "react-router-dom";
import stationJson from "../../goStations.json";
import { PoiSelect } from "../common/poiSelect";
import { usePointsInterest } from "./hook/pointsInterest";
import { placeWorshipOptions, pointsOfInterest } from "./data";
import { updatePreferences } from "../../services/preferences";
import { useUserProfileStore } from "../../stores/profileStore";
import { useCallback, useEffect, useRef, useState } from "react";
import { MinusIcon, LocationIcon, PlusIcon } from "../../assets/icons";
import { useHomeFeaturesStore } from "../../stores/buyerHomeFeaturesStore";
import { AddressAutocomplete } from "../common/googleAddressLoop/addressAutocomplete";
import { BuyerMatchingPreferencesPoiTypeEnum } from "../../interfaces/buyerMatchingPreferences";

interface SelectOption {
	value: string;
	label: string;
	lat?: number;
	lon?: number;
}

const typeToNameMap = {
	[BuyerMatchingPreferencesPoiTypeEnum.School]: "School",
	[BuyerMatchingPreferencesPoiTypeEnum.PublicStation]: "GO Station",
	[BuyerMatchingPreferencesPoiTypeEnum.Work]: "Work",
	[BuyerMatchingPreferencesPoiTypeEnum.PlaceOfWorship]: "Place of worship",
	[BuyerMatchingPreferencesPoiTypeEnum.Other]: "Address",
};

const PointsInterest = () => {
	const {
		removeSelectedAddress,
		addPointOfInterest,
		removePointOfInterest,
		selectedAddresses,
		setSelectedAddresses,
		selectedPoints,
		remainingPopularPoints,
		pointsInterestError,
		setRemainingPopularPoints,
		setPointsInterestError,
		setCurrentStep,
		placeWorship,
		setPlaceWorship,
		selectedStation,
		setSelectedStation,
		selectedSchool,
		setSelectedSchool,
		selectedWorkAddress,
		setSelectedWorkAddress,
		setSelectedPoints,
	} = usePointsInterest();
	const {
		selectedStationLat,
		selectedStationLon,
		selectedSchoolLat,
		selectedSchoolLon,
		selectedWorkLat,
		selectedWorkLon,
	} = useHomeFeaturesStore();

	const { userProfile } = useUserProfileStore();
	const navigate = useNavigate();
	const profilePOIsProcessed = useRef(false);
	const [disabledButton, setDisabledButton] = useState(false);
	const buttonContainerRef = useRef<HTMLDivElement>(null);

	const getNameFromType = useCallback(
		(type) => typeToNameMap[type] || "Address",
		[],
	);

	useEffect(() => {
		if (
			!profilePOIsProcessed.current &&
			userProfile?.matchingPreferences?.pointsOfInterests
		) {
			const poiArray = userProfile.matchingPreferences.pointsOfInterests;
			const addedPOISet = new Set(
				selectedPoints.map((point) => `${point.name}-${point.type}`),
			);

			for (const { designation, location, type } of poiArray) {
				const lat = location?.coordinates[1] || null;
				const lon = location?.coordinates[0] || null;
				const uniqueKey = `${designation}-${type}`;

				if (!designation || addedPOISet.has(uniqueKey)) {
					continue;
				}

				const name = getNameFromType(type); // Get the name format used in `remainingPopularPoints`
				const icon =
					pointsOfInterest.find((p) => p.name === name)?.icon || LocationIcon;

				// Add the POI to the selected points
				addPointOfInterest({
					id: nanoid(),
					name: designation,
					type,
					latitude: lat,
					longitude: lon,
					icon,
				});

				// Set specific states for different types
				switch (type) {
					case BuyerMatchingPreferencesPoiTypeEnum.School:
						setSelectedSchool(designation, lat, lon);
						break;
					case BuyerMatchingPreferencesPoiTypeEnum.PublicStation:
						setSelectedStation(designation, lat, lon);
						break;
					case BuyerMatchingPreferencesPoiTypeEnum.Work:
						setSelectedWorkAddress(designation, lat, lon);
						break;
					case BuyerMatchingPreferencesPoiTypeEnum.PlaceOfWorship:
						setPlaceWorship(designation);
						break;
					case BuyerMatchingPreferencesPoiTypeEnum.Other:
						setSelectedAddresses((prev) => [
							...prev,
							{
								id: nanoid(),
								name: designation,
								latitude: lat,
								longitude: lon,
							},
						]);
						break;
					default:
						break;
				}
			}

			profilePOIsProcessed.current = true;
		}
	}, [
		addPointOfInterest,
		selectedPoints,
		setSelectedAddresses,
		setSelectedSchool,
		setSelectedStation,
		setSelectedWorkAddress,
		setPlaceWorship,
		userProfile?.matchingPreferences?.pointsOfInterests,
		getNameFromType,
	]);

	const stationOptions = stationJson.map((stop) => ({
		value: stop.stop_name,
		label: stop.stop_name,
		lat: Number.parseFloat(stop.stop_lat) || 0,
		lon: Number.parseFloat(stop.stop_lon) || 0,
	}));

	const schoolOptions = schoolJson.features.map((feature) => ({
		value: feature.properties.title,
		label: feature.properties.title,
		lat: Number.parseFloat(feature.geometry.coordinates[1]) || 0,
		lon: Number.parseFloat(feature.geometry.coordinates[0]) || 0,
	}));

	const handleSave = async () => {
		const pointsOfInterests = [];

		console.log("Selected Points Before Saving:", selectedPoints);

		if (selectedSchool && selectedSchoolLat && selectedSchoolLon) {
			pointsOfInterests.push({
				designation: selectedSchool,
				location: {
					type: "Point",
					coordinates: [selectedSchoolLon, selectedSchoolLat],
				},
				type: BuyerMatchingPreferencesPoiTypeEnum.School,
			});
		}

		if (selectedStation && selectedStationLat && selectedStationLon) {
			pointsOfInterests.push({
				designation: selectedStation,
				location: {
					type: "Point",
					coordinates: [selectedStationLon, selectedStationLat],
				},
				type: BuyerMatchingPreferencesPoiTypeEnum.PublicStation,
			});
		}

		if (selectedWorkAddress && selectedWorkLat && selectedWorkLon) {
			pointsOfInterests.push({
				designation: selectedWorkAddress,
				location: {
					type: "Point",
					coordinates: [selectedWorkLon, selectedWorkLat],
				},
				type: BuyerMatchingPreferencesPoiTypeEnum.Work,
			});
		}

		if (placeWorship) {
			pointsOfInterests.push({
				designation: placeWorship,
				type: BuyerMatchingPreferencesPoiTypeEnum.PlaceOfWorship,
			});
		}

		for (const address of selectedAddresses) {
			pointsOfInterests.push({
				designation: address.name,
				location: {
					type: "Point",
					coordinates: [address.longitude, address.latitude],
				},
				type: BuyerMatchingPreferencesPoiTypeEnum.Other,
			});
		}

		console.log("Final pointsOfInterests:", pointsOfInterests);
		setDisabledButton(true);
		await updatePreferences(pointsOfInterests);
		setDisabledButton(false);

		navigate("/profile/life-style");
	};
	const nameToTypeMap = {
		School: BuyerMatchingPreferencesPoiTypeEnum.School,
		"GO Station": BuyerMatchingPreferencesPoiTypeEnum.PublicStation,
		Work: BuyerMatchingPreferencesPoiTypeEnum.Work,
		"Place of worship": BuyerMatchingPreferencesPoiTypeEnum.PlaceOfWorship,
		Address: BuyerMatchingPreferencesPoiTypeEnum.Other,
		// Add other mappings as needed
	};

	const getTypeFromName = (name) =>
		nameToTypeMap[name] || BuyerMatchingPreferencesPoiTypeEnum.Other;
	const handleRemovePointOfInterest = (point) => {
		const type = point.type || getTypeFromName(point.name); // Ensure type is set based on name
		removePointOfInterest({ ...point, type }); // Pass the point with the derived type
		setSelectedPoints((prev) => prev.filter((p) => p.id !== point.id)); // Update store
	};

	// Remove Address and update the store
	const handleRemoveSelectedAddress = (addressId) => {
		removeSelectedAddress(addressId);
		setSelectedAddresses((prev) => prev.filter((a) => a.id !== addressId)); // Update store
	};
	return (
		<div className="w-full h-full pt-2 text-z-shark absolute inset-0 bg-white">
			<h2 className="text-z-shark text-2xl font-bold mb-2 px-4">
				Points of Interest
			</h2>
			{pointsInterestError && (
				<div className="bg-black bg-opacity-15 fixed top-0 right-0 left-0 bottom-0 w-full h-full z-20">
					<div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-11/12 flex items-center justify-center flex-col py-7 px-6 rounded-2xl bg-white">
						<div className="text-2xl font-semibold text-z-shark">
							Skip Points of Interest?
						</div>
						<div className="text-base text-z-shark mt-4 mb-6">
							You haven't selected any areas or points of interest. Skipping
							this step means we won't be able to prioritize locations that
							matter to you. Are you sure you want to continue?
						</div>
						<div className="text-[#ADADAD] text-sm">
							This can be changed later
						</div>
						<div className="flex flex-col w-full mt-8 space-y-5">
							<Button
								onClick={() => {
									setPointsInterestError(false);
								}}
								variant="primary"
								customClassName="font-bold text-lg bg-white"
								height="45px"
								width="full"
								radius="60px"
							>
								Add Point of Interest
							</Button>
							<Button
								onClick={() => {
									navigate("/buyer-input-flow/lifestyle");
									setCurrentStep(10);
								}}
								customClassName="font-bold text-lg bg-white"
								height="45px"
								isOutlined
								width="full"
								radius="60px"
							>
								Skip
							</Button>
						</div>
					</div>
				</div>
			)}
			<span className="text-z-shark text-sm px-4">
				Where would you like to live near
			</span>
			<div className="mt-3 relative mb-6 px-4">
				<AddressAutocomplete
					onSelect={(address, latitude, longitude) => {
						setSelectedAddresses((prev) => [
							...prev,
							{ id: Date.now(), name: address, latitude, longitude },
						]);
					}}
					placeholder="Enter an address"
					className="h-16 w-full text-z-shark font-medium text-sm px-3 rounded-xl border border-[#EAEAEA] placeholder:text-[#D1D1D1]"
				/>
			</div>
			<div className="h-[calc(100%_-_250px)] overflow-y-auto px-4">
				{remainingPopularPoints.length > 0 && (
					<div className="mt-6 space-y-3">
						<span className="text-sm font-semibold text-z-shark">
							Popular points
						</span>
						<div className="flex items-center flex-wrap gap-x-3 gap-y-4">
							{remainingPopularPoints.map((point) => (
								<div
									key={point.id}
									onClick={() => addPointOfInterest(point)}
									onKeyUp={(event) => {
										if (event.key === "Enter" || event.key === " ") {
											addPointOfInterest(point);
										}
									}}
									className="px-5 space-x-6 py-3 rounded-full bg-[#EFF6FF] flex items-center justify-between"
								>
									<div className="flex items-center space-x-2">
										<img src={point.icon} alt={`${point.name} icon`} />
										<span className="text-z-shark text-sm font-semibold">
											{point.name}
										</span>
										<PlusIcon />
									</div>
								</div>
							))}
						</div>
					</div>
				)}
				{selectedPoints.length > 0 && (
					<div className="space-y-6 mt-6">
						{selectedPoints.map((point) => (
							<div key={point.id}>
								<div className="flex items-center justify-between">
									<div className="flex items-center space-x-3">
										<img className="w-8" src={point.icon} alt="" />
										<div className="text-xl font-semibold">{point.name}</div>
									</div>
									<div
										className="w-4 h-4 flex items-center"
										onClick={() => handleRemovePointOfInterest(point)}
										onKeyUp={(event) => {
											if (event.key === "Enter" || event.key === " ") {
												handleRemovePointOfInterest(point);
											}
										}}
									>
										<MinusIcon />
									</div>
								</div>
								<div className="mt-3">
									{point.id === 1 && (
										<div>
											{selectedStation === "" ? (
												<PoiSelect
													options={stationOptions}
													value={selectedStation}
													onChange={(option: SelectOption) => {
														setSelectedStation(
															option.value,
															option.lat !== undefined ? option.lat : 0,
															option.lon !== undefined ? option.lon : 0,
														);
													}}
													search={true}
													placeholder="Select a station"
												/>
											) : (
												<div className="text-z-shark text-sm font-normal ml-11">
													{selectedStation}
												</div>
											)}
										</div>
									)}
									{point.id === 2 &&
										(selectedWorkAddress === "" ? (
											<div className="mt-3 relative mb-10">
												<AddressAutocomplete
													onSelect={(address, latitude, longitude) => {
														setSelectedWorkAddress(
															address,
															latitude,
															longitude,
														);
													}}
													placeholder="Enter your work address"
													className="h-16 w-full text-z-shark font-medium text-sm px-3 rounded-xl border border-[#EAEAEA] placeholder:text-[#D1D1D1]"
												/>
											</div>
										) : (
											<div className="text-z-shark text-sm font-normal ml-11">
												{selectedWorkAddress}
											</div>
										))}
									{point.id === 3 &&
										(selectedSchool === "" ? (
											<PoiSelect
												options={schoolOptions}
												value={selectedSchool}
												onChange={(option: SelectOption) => {
													setSelectedSchool(
														option.value,

														option.lat !== undefined ? option.lat : 0,
														option.lon !== undefined ? option.lon : 0,
													);
												}}
												search={true}
												placeholder="Select a school"
											/>
										) : (
											<div className="text-z-shark text-sm font-normal ml-11">
												{selectedSchool}
											</div>
										))}
									{point.id === 4 &&
										(placeWorship === "" ? (
											<Select
												options={placeWorshipOptions}
												value={placeWorship}
												onChange={setPlaceWorship}
												placeholder="Select one"
											/>
										) : (
											<div className="text-z-shark text-sm font-normal ml-11">
												{placeWorship}
											</div>
										))}
								</div>
							</div>
						))}
					</div>
				)}
				{selectedAddresses.length > 0 && (
					<div className="mt-3">
						{selectedAddresses.map((address) => (
							<div
								key={address.id}
								className="flex items-center justify-between mt-8"
							>
								<div className="flex items-center space-x-5">
									<img className="w-6" src={LocationIcon} alt="location icon" />
									<div className="text-sm text-z-shark">{address.name}</div>
								</div>
								<div
									className="w-4 h-4 items-center flex"
									onClick={() => handleRemoveSelectedAddress(address.id)}
									onKeyUp={(event) => {
										if (event.key === "Enter" || event.key === " ") {
											handleRemoveSelectedAddress(address.id);
										}
									}}
								>
									<MinusIcon />
								</div>
							</div>
						))}
					</div>
				)}
			</div>

			<div
				ref={buttonContainerRef}
				className={`absolute bottom-0  ${
					window.bridge ? "pb-bridge-inset-bottom" : "pb-4"
				} left-0 w-full h-[10vh] z-50 flex justify-center items-center`}
			>
				<button
					type="button"
					className={`bg-z-dark-moss-green  w-3/4 p-4 rounded-3xl font-bold text-white disabled:bg-[#D9D9D9] absolute left-1/2 -translate-x-1/2  ${
						window.bridge ? "bottom-bridge-inset-bottom" : "bottom-5"
					}`}
					onClick={handleSave}
					disabled={disabledButton}
				>
					Continue
				</button>
			</div>
		</div>
	);
};

export { PointsInterest };
