import "./styles/globals.css";
import { routes } from "./routes";
import { StrictMode } from "react";
import { AuthProvider } from "./context/authProvider";
import { RouterProvider, createMemoryRouter } from "react-router-dom";
import { type Container, type RootOptions, createRoot } from "react-dom/client";

const router = createMemoryRouter(routes(), {
	initialEntries: ["/"],
	future: {},
	initialIndex: 0,
	// basename: "",
});

const rootOptions: RootOptions = {
	onRecoverableError(error, errorInfo) {
		console.error(error);
		console.error(errorInfo);
	},
};

const rootElement = document.getElementById("root") as Container;

createRoot(rootElement, rootOptions).render(
	<StrictMode>
		<AuthProvider>
			<RouterProvider router={router} />
		</AuthProvider>
	</StrictMode>
);
