import millify from "millify";
import { Marker } from "react-map-gl";
import type { PointFeature } from "supercluster";
import { PiBuildingBold } from "react-icons/pi";
import HouseIcon from "../../../assets/icons/houseIcon.svg";
import { convertToFormattedAmount } from "../../../utils/convertAmount";
import type { SearchListingInterface } from "../../../services/exploreListings";

interface ClusterProperties {
	cluster: boolean;
	point_count: number;
	cluster_id: number;
	propertyId?: string;
}

interface PointProperties extends SearchListingInterface {
	cluster: false;
	unit: SearchListingInterface[];

	propertyId: string | number | null; // Allow null
}

type MarkerPoint =
	| PointFeature<PointProperties>
	| PointFeature<ClusterProperties>;

interface MarkerProps {
	supercluster: any;
	clusters: MarkerPoint[];
	onMarkerClick: (
		clusterId: number | null,
		longitude: number,
		latitude: number,
	) => void;
}

const Markers = ({ clusters, onMarkerClick, supercluster }: MarkerProps) => {
	const renderClusterMarker = (pointCount: number) => (
		<div className="cluster-marker bg-z-dark-moss-green border-white border-4 shadow-lg text-white rounded-full w-[50px] h-[50px] text-xl flex items-center justify-center">
			{pointCount}
		</div>
	);

	console.log("clusters", clusters);

	const renderCustomMarker = (property: SearchListingInterface) => (
		<div className="bg-z-dark-moss-green font-medium text-[17px] border-white border-4 rounded-full px-2 py-1 shadow-lg text-white flex gap-2 items-center justify-center">
			{property.propertyType === "Condo Townhouse" ||
			property.propertyType === "Condo Apt" ? (
				<PiBuildingBold />
			) : (
				<img src={HouseIcon} alt="House Icon" className="w-[16.49px]" />
			)}
			<span>
				{millify(convertToFormattedAmount(property.listPrice)) || "Property"}
			</span>
			<div className="w-0 h-0 border-l-[8px] border-r-[8px] border-t-[12px] border-transparent border-t-white absolute -bottom-[10px] left-1/2 -translate-x-1/2">
				<div className="w-0 h-0 border-l-[8px] border-t-z-dark-moss-green border-r-[8px] border-t-[14px] border-transparent absolute -bottom-[4px] pt-2 left-1/2 -translate-x-1/2" />
			</div>
		</div>
	);

	const renderCustomGroupedMarker = (properties: SearchListingInterface[]) => (
		<div className="relative font-medium text-[17px] border-white border-4 bg-z-dark-moss-green rounded-full px-2 py-1 shadow-lg text-white flex gap-2 items-center justify-center">
			<PiBuildingBold />
			<span>{properties.length} units</span>
			<div className="w-0 h-0 border-l-[8px] border-r-[8px] border-t-[12px] border-transparent border-t-white absolute -bottom-[14px] left-1/2 -translate-x-1/2">
				<div className="w-0 h-0 border-l-[8px] border-r-[8px] border-t-z-dark-moss-green border-t-[14px] border-transparent absolute -bottom-[4px] pt-2 left-1/2 -translate-x-1/2" />
			</div>
		</div>
	);

	return (
		<>
			{clusters.map((cluster) => {
				const [longitude, latitude] = cluster.geometry.coordinates;
				const {
					cluster: isCluster,
					point_count: pointCount,
					cluster_id: clusterId,
					unit,
					...properties
				} = cluster.properties;

				if (isCluster) {
					const clusteredPoints = supercluster?.getLeaves(
						clusterId,
						Number.POSITIVE_INFINITY,
					);
					const totalUnits = clusteredPoints?.reduce((sum, point) => {
						return sum + (point.properties.unit?.length || 0);
					}, 0);
					return (
						<Marker
							key={`cluster-${clusterId}`}
							longitude={longitude}
							latitude={latitude}
						>
							<button
								type="button"
								onClick={() => onMarkerClick(clusterId, longitude, latitude)}
								style={{
									background: "none",
									border: "none",
									padding: 0,
									cursor: "pointer",
								}}
							>
								{renderClusterMarker(totalUnits || pointCount)}{" "}
							</button>
						</Marker>
					);
				}

				if (Array.isArray(unit) && unit.length > 1) {
					return (
						<Marker
							key={`grouped-${longitude}-${latitude}`}
							longitude={longitude}
							latitude={latitude}
						>
							<button
								type="button"
								onClick={() => onMarkerClick(null, longitude, latitude)}
								style={{
									background: "none",
									border: "none",
									padding: 0,
									cursor: "pointer",
								}}
							>
								{renderCustomGroupedMarker(unit as SearchListingInterface[])}
							</button>
						</Marker>
					);
				}

				// Render single property marker
				if (properties.propertyId) {
					// Ensure propertyId is valid
					return (
						<Marker
							key={`property-${properties.propertyId}`}
							longitude={longitude}
							latitude={latitude}
							offset={[0, -25]}
						>
							<button
								type="button"
								onClick={() => onMarkerClick(null, longitude, latitude)}
								style={{
									background: "none",
									border: "none",
									padding: 0,
									cursor: "pointer",
								}}
							>
								{renderCustomMarker(properties as SearchListingInterface)}
							</button>
						</Marker>
					);
				}

				return null;
			})}
		</>
	);
};

export { Markers };
