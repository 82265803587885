import { LoadingCircle } from "../common";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ProfileSection } from "./profileSection";
import LogOutIcon from "../../assets/logOutIcon.svg";
import ChatIcon from "../../assets/Profile/chatIcon.svg";
import InfoIcon from "../../assets/Profile/infoIcon.svg";
import HomeIcon from "../../assets/Profile/homeIcon.svg";
import { fetchAccountData } from "../../services/account";
import ArrowRightBlack from "../../assets/arrowRightBlack.svg";
import { useUserProfileStore } from "../../stores/profileStore";
import LikedHomesIcon from "../../assets/Profile/likedHomesIcon.svg";
import { useLogOutModalStore } from "../../stores/showLogOutModalStore";
import GetPreApproved from "../../assets/Profile/getPreApprovedIcon.svg";
import DefaultProfilePic from "../../assets/profile/defaultProfilePic.svg";
import ScheduledShowingsIcon from "../../assets/Profile/scheduledShowingIcon.svg";

function arrayBufferToBase64(buffer: ArrayBuffer): string {
	let binary = "";
	const bytes = new Uint8Array(buffer);
	const len = bytes.byteLength;
	for (let i = 0; i < len; i++) {
		binary += String.fromCharCode(bytes[i]);
	}
	return window.btoa(binary);
}

const ProfileOverview = () => {
	const { userProfile } = useUserProfileStore();
	const [profileImageSrc, setProfileImageSrc] =
		useState<string>(DefaultProfilePic);

	const navigate = useNavigate();

	useEffect(() => {
		const imageUrl = userProfile?.profileImageUrl;

		if (imageUrl instanceof ArrayBuffer) {
			const base64String = arrayBufferToBase64(imageUrl);
			setProfileImageSrc(`data:image/jpeg;base64,${base64String}`);
		} else if (typeof imageUrl === "string") {
			setProfileImageSrc(imageUrl);
		} else {
			setProfileImageSrc(DefaultProfilePic);
		}
	}, [userProfile]);

	return (
		<div className="w-full space-y-4 text-start">
			<div className="font-bold">Overview</div>
			<button
				type="button"
				className="flex items-center justify-between text-start w-full gap-1 bg-white px-4 py-2.5 rounded-[15px] shadow-sm drop-shadow"
				onClick={() => navigate("/profile/overview")}
			>
				<div className="flex items-center gap-4">
					<div>
						<img
							src={profileImageSrc}
							className="w-[65px] h-[65px] rounded-full"
							alt="Profile"
						/>
					</div>
					<div>
						<div className="text-2xl font-bold">
							{userProfile?.firstName} {userProfile?.lastName}
						</div>
						<div className="text-sm">
							name, email, phone number, and more...
						</div>
					</div>
				</div>
				<div>
					<img src={ArrowRightBlack} alt="Arrow Right" />
				</div>
			</button>
		</div>
	);
};

const ProfileView = () => {
	const priorityItems = [
		{
			imageSrc: ScheduledShowingsIcon,
			title: "Scheduled showings",
			description: "view showings that I have scheduled",
			link: "/profile/showing",
		},
		{
			imageSrc: LikedHomesIcon,
			title: "Liked homes",
			description: "your saved homes",
			link: "/profile/liked-homes",
		},
		{
			imageSrc: GetPreApproved,
			title: "Get pre-approved",
			description: "get your pre-approval started",
			// link: "/profile/get-preapproved",
		},
		// {
		// 	imageSrc: UploadYourPreApproval,
		// 	title: "Upload your pre-approval",
		// 	description: "already pre-approved? upload it here",
		// 	link: "/profile/upload-preapproved",
		// },
		{
			imageSrc: HomeIcon,
			title: "Your matching preferences",
			description: "update your preferences for your matching",
			// link: "/profile/upload-preapproved",
		},
	];

	const helpItems = [
		{
			imageSrc: ChatIcon,
			title: "Chat with us",
			description: "speak to your account manager",
		},
	];

	const zownInfoItems = [
		{
			imageSrc: InfoIcon,
			title: "Notifications",
			description: "set your notification preferences",
		},
		{
			imageSrc: InfoIcon,
			title: "Privacy Policy",
			description: "read our privacy policy",
		},
		{
			imageSrc: InfoIcon,
			title: "Terms & Conditions",
			description: "read our terms & conditions",
		},
	];
	const { setShowLogOutModal } = useLogOutModalStore();
	const { setUserProfile } = useUserProfileStore();

	const [loading, setLoading] = useState<boolean>(false);
	useEffect(() => {
		const getProfile = async () => {
			try {
				setLoading(true);
				const accountData = await fetchAccountData();

				if (accountData) {
					setUserProfile(accountData);
				}

				setLoading(false);
			} catch (error) {
				console.error("Failed to getProfile", error);
			}
		};

		getProfile();
	}, [setUserProfile]);

	return (
		<div
			className={`flex flex-col w-full ${loading ? "h-screen" : ""} space-y-10 text-black px-2 pt-2 pb-6`}
		>
			{loading && (
				<div className="flex items-center h-full justify-center">
					<LoadingCircle />
				</div>
			)}
			{!loading && (
				<>
					<ProfileOverview />
					<ProfileSection title="Priority" items={priorityItems} />
					<ProfileSection title="Let us help you" items={helpItems} />
					<ProfileSection title="Zown information" items={zownInfoItems} />
					<button
						onClick={() => setShowLogOutModal(true)}
						type="button"
						className="w-full flex justify-center gap-3 items-center"
					>
						<img src={LogOutIcon} alt="" />
						<div>Logout</div>
					</button>
				</>
			)}
		</div>
	);
};

export { ProfileView };
