import { Bridge } from "../bridge.mts";

export const openLink = async (url: string) => {
  try {
    const bridgeVersion = await Bridge.getBridgeVersion().catch(() => null);

    if (bridgeVersion) {
      console.log(`Using Bridge version: ${bridgeVersion}`);
      await Bridge.openURL(url);
    } else {
      console.warn(
        "Bridge version not available. Falling back to window.open."
      );
      window.open(url, "_blank");
    }
  } catch (error) {
    console.error("Error in openLink. Falling back to window.open:", error);
    window.open(url, "_blank");
  }
};

export const openNotification = () => {
  return Bridge.openNotificationSettings();
};
