import { MedianSoldPrice } from "./medianSoldPrice";
import { BestMonthsToSell } from "./bestMonthsToSell";
import { useAuth } from "../../../context/authProvider";
import { formatPrice } from "../../../utils/formatPrice";
import { SoldActiveListings } from "./soldActiveListings";
import { useEffect, useState, type KeyboardEvent } from "react";
import { MedianSoldPriceAvgDom } from "./medianSoldPriceAvgDom";
import GrayArrowDownIcon from "../../../assets/grayArrowDownIcon.svg";
import type { StatisticsResponse } from "../../../interfaces/statistics";
import AboutMarketIcon from "../../../assets/Listing/aboutMarketIcon.svg";
import MarketFacourabilityMarkerIcon from "../../../assets/Listing/marketFacourabilityMarker.svg";

interface AboutMarketProps {
	statistics?: StatisticsResponse | null;
}

const AboutMarket = ({ statistics }: AboutMarketProps) => {
	const [isExpanded, setIsExpanded] = useState(true);
	const { isWebview } = useAuth();
	const [avgSoldPrice, setAvgSoldPrice] = useState(0);
	const [avgDaysOnMarket, setAvgDaysOnMarket] = useState(0);
	const [homesSold, setHomesSold] = useState(0);

	const toggleSection = () => {
		setIsExpanded((prevState) => !prevState);
	};

	const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
		if (e.key === "Enter" || e.key === " ") {
			e.preventDefault();
			toggleSection();
		}
	};

	useEffect(() => {
		setAvgSoldPrice(statistics?.listingStatistics?.avgSoldPrice ?? 0);
		setAvgDaysOnMarket(statistics?.listingStatistics?.avgDaysOnMarket ?? 0);
		setHomesSold(statistics?.listingStatistics?.homesSold ?? 0);
	}, [statistics]);

	const marketFavourabilityPercentage = (() => {
		if (avgDaysOnMarket <= 14) {
			return 100 - (avgDaysOnMarket / 14) * 33;
		}

		if (avgDaysOnMarket <= 30) {
			return 67 - ((avgDaysOnMarket - 14) / 16) * 34;
		}

		return 33 - ((avgDaysOnMarket - 30) / 30) * 33;
	})();

	const clampedPercentage = Math.min(
		Math.max(marketFavourabilityPercentage, 5),
		95,
	);
	const markerPosition = `${clampedPercentage}%`;

	return (
		<div className="w-full bg-white flex justify-between rounded-[20px] py-4 items-start shadow-md drop-shadow-lg">
			<div className="w-full">
				<div
					className="text-black font-semibold text-xl flex items-center justify-between w-full px-4 cursor-pointer"
					onClick={toggleSection}
					onKeyDown={handleKeyDown}
					aria-expanded={isExpanded}
					aria-controls="about-market-content"
				>
					<div className="flex items-center gap-2">
						<img src={AboutMarketIcon} alt="" />
						About The Market
					</div>
					<img
						src={GrayArrowDownIcon}
						alt="Toggle Section"
						className={`transition-transform duration-300 ${
							isExpanded ? "rotate-180" : ""
						}`}
					/>
				</div>

				<div
					id="about-market-content"
					className={`overflow-hidden transition-[max-height] duration-300 ease-in-out space-y-6 ${
						isExpanded ? "max-h-auto" : "max-h-0"
					}`}
				>
					<hr className="h-px bg-[#F3F3F3] border-0 mt-6" />
					<div className="px-4 space-y-8">
						<div className="font-semibold text-lg">Your Market</div>
						<div className="relative flex justify-between">
							<div
								className="absolute -top-6"
								style={{
									left: markerPosition,
									transform: "translateX(-50%)",
								}}
							>
								<img src={MarketFacourabilityMarkerIcon} alt="Market Marker" />
							</div>

							<div className="rounded-l-[8px] w-full text-xs text-center text-white font-bold bg-z-dark-moss-green py-2">
								Buyer's Market
							</div>
							<div className="w-full text-xs text-center text-black font-bold bg-z-abbey py-2">
								Balanced
							</div>
							<div className="rounded-r-[8px] w-full text-xs text-center text-white font-bold bg-z-prussian-blue py-2">
								Seller's Market
							</div>
						</div>

						<div className="flex justify-between w-full text-center items-start">
							<div className="flex items-center text-center justify-center flex-col w-full border-r border-r-[#E1E0E3] px-1">
								<div className="text-[#676473] text-xs">
									Average Sold
									<br /> Price
								</div>
								<div className="font-bold">${formatPrice(avgSoldPrice)}</div>
							</div>
							<div className="flex items-center text-center justify-center flex-col w-full border-r border-r-[#E1E0E3] px-1">
								<div className="text-[#676473] text-xs">
									Average Days on <br />
									Market
								</div>
								<div className="font-bold">{formatPrice(avgDaysOnMarket)}</div>
							</div>
							<div className="flex items-center text-center justify-center flex-col w-full px-1">
								<div className="text-[#676473] text-xs">
									Homes
									<br /> Sold
								</div>
								<div className="font-bold">{formatPrice(homesSold)}</div>
							</div>
						</div>
					</div>

					{statistics?.historicGrowthRates && (
						<>
							<hr className="h-px mx-4 bg-[#F3F3F3] border-0 text-center" />
							<div className="space-y-5 px-4">
								<div>
									<div className="font-semibold text-lg">
										Historic Growth Rate
									</div>
									<div className="text-[#676473] text-xs">
										3-year compounded annual growth rate
									</div>
								</div>
								<div className="flex justify-between items-center">
									<div className="flex flex-col items-center border-r border-r-[#E1E0E3] justify-center w-full">
										<div className="text-xs text-[#676473] text-center">
											{statistics?.historicGrowthRates?.neighborhood
												?.location ?? ""}
										</div>
										<div className="text-[#4052B5] font-bold">
											{statistics?.historicGrowthRates?.neighborhood?.cagr !=
											null
												? `${statistics.historicGrowthRates.neighborhood.cagr.toFixed(2)}%`
												: "--"}
										</div>
									</div>
									<div className="flex flex-col items-center border-r border-r-[#E1E0E3] justify-center w-full">
										<div className="text-xs text-[#676473] text-center">
											{statistics?.historicGrowthRates?.area?.location ?? "0"}
										</div>
										<div className="text-[#00D4A1] font-bold">
											{statistics?.historicGrowthRates?.area?.cagr != null
												? `${statistics.historicGrowthRates.area.cagr.toFixed(2)}%`
												: "--"}
										</div>
									</div>
									<div className="flex flex-col items-center justify-center w-full">
										<div className="text-xs text-[#676473] text-center">
											{statistics?.historicGrowthRates?.city?.location ?? ""}
										</div>
										<div className="text-[#A02CE7] font-bold">
											{statistics?.historicGrowthRates?.city?.cagr != null
												? `${statistics.historicGrowthRates.city.cagr.toFixed(2)}%`
												: "--"}
										</div>
									</div>
								</div>
							</div>
						</>
					)}
					{statistics?.bestMonthsToSell && (
						<>
							<hr className="h-px mx-4 bg-[#F3F3F3] border-0" />
							<BestMonthsToSell
								bestMonthsToSell={statistics?.bestMonthsToSell}
							/>
						</>
					)}

					{!isWebview && statistics?.medianSoldPrice && (
						<>
							<hr className="h-px mx-4 bg-[#F3F3F3] border-0" />
							<MedianSoldPrice medianSoldPrice={statistics.medianSoldPrice} />
						</>
					)}
					{statistics?.soldActiveListings && (
						<>
							<hr className="h-px mx-4 bg-[#F3F3F3] border-0" />
							<SoldActiveListings
								soldActiveListings={statistics.soldActiveListings}
							/>
						</>
					)}
					{statistics?.averageDaysOnMarket && (
						<MedianSoldPriceAvgDom
							medianSoldPriceAvgDom={statistics.averageDaysOnMarket}
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export { AboutMarket };
