import moment from "moment";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ClockIcon from "../../assets/clockIcon.svg";
import { TimePreferenceItem } from "./preferenceItem";
import { formatDate } from "../../utils/dateFormatter";
import KeepInMindIcon from "../../assets/keepInMindIcon.svg";
import { useShowingCartStore } from "../../stores/showingCartStore";
import type { TimePreference } from "../../interfaces/timePreference";
import { generateInitialTimePreferences } from "../../utils/generateTimePreferences";

const ShowingTimeSelectionView = () => {
	const navigate = useNavigate();
	const { dates, setAvailableSlots, zlids } = useShowingCartStore();
	const [timePreferences, setTimePreferences] = useState<TimePreference[]>([]);
	const showingsCount = zlids.length;

	useEffect(() => {
		setTimePreferences(generateInitialTimePreferences(dates));
	}, [dates]);

	const handleInputChange = (
		index: number,
		field: keyof TimePreference,
		value: string,
	) => {
		const newTimePreferences = [...timePreferences];
		newTimePreferences[index][field] = value;
		newTimePreferences[index].error = "";

		if (field === "startAt") {
			const [hour, minute] = value.split(":").map(Number);
			const endAt = moment()
				.hour(hour)
				.minute(minute)
				.add(showingsCount, "hours")
				.format("HH:mm");
			newTimePreferences[index].endAt = endAt;
		}

		setTimePreferences(newTimePreferences);
	};

	const handleConfirm = () => {
		const updatedPreferences = timePreferences.map((preference) => {
			if (!preference.startAt) {
				return { ...preference, error: "Select start time" };
			}
			return { ...preference, error: "" };
		});
		setTimePreferences(updatedPreferences);

		const isValid = updatedPreferences.every((preference) => !preference.error);
		if (isValid) {
			const confirmedTimes = timePreferences.map((preference) => {
				const dateWithoutTime = formatDate(preference.date, "date");
				const startAt = new Date(`${dateWithoutTime}T${preference.startAt}:00`);
				const endAt = new Date(`${dateWithoutTime}T${preference.endAt}:00`);
				return { startAt, endAt };
			});

			setAvailableSlots(confirmedTimes);

			navigate("/cart/details-confirmation");
		}
	};

	return (
		<div className="flex flex-col items-center text-black space-y-6 w-full pt-2 pb-6">
			<div className="text-center space-y-2 text-black px-4">
				<div className="text-[22px] font-semibold">
					Set your time preferences
				</div>
				<div className="text-sm text-black">
					Minimum of 1 hour per showing
					<br />
					(eg. {showingsCount} showings is a minimum of {showingsCount} hours)
				</div>
			</div>
			<div className="w-full space-y-8 text-black px-4">
				{timePreferences.map((preference, index) => (
					<TimePreferenceItem
						key={preference.id}
						preference={preference}
						index={index}
						onInputChange={handleInputChange}
						showingsCount={showingsCount}
					/>
				))}
				<div className="bg-[#FAFAFA] rounded-[11px] px-4 py-6 flex gap-4 items-center shadow-md drop-shadow-md">
					<img src={KeepInMindIcon} alt="" />
					<div className="font-light">
						<span className="font-bold">Keep in mind:</span> Each showing takes
						approximately 60 minutes, so plan accordingly.
					</div>
				</div>
			</div>

			<div className="w-full">
				<hr className="h-px mt-2 mb-4 bg-[#EDF1F4] border-0" />
				<div className="px-4">
					<button
						type="button"
						onClick={handleConfirm}
						className="bg-z-dark-moss-green w-full font-semibold text-[20px] rounded-full text-white py-3.5 shadow-md drop-shadow-md flex items-center justify-center gap-3"
					>
						<img src={ClockIcon} alt="Clock Icon" />
						Confirm my showings
					</button>
				</div>
			</div>
		</div>
	);
};

export { ShowingTimeSelectionView };
