import moment from "moment";
import { useNavigate } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import { formatPrice } from "../../../utils/formatPrice";
import { formatDate } from "../../../utils/dateFormatter";
import type { PropertyInfoProps } from "../../../interfaces/listing";
import GreenArrowDownIcon from "../../../assets/greenArrowDownIcon.svg";
import { convertToFormattedAmount } from "../../../utils/convertAmount";
import { useAuth } from "../../../context/authProvider";
import { openLink } from "../../../services/openLink";
import { useShowLoginModalStore } from "../../../stores/loginModalStore";

interface PropertyInfoComponentProps {
	info: PropertyInfoProps;
	isLast: boolean;
}

const STATUS_MAP: Record<string, string> = {
	sld: "Sold",
	ter: "Terminated",
	new: "For Sale",
	sus: "Suspended",
	lsd: "Leased",
	sc: "Sold Conditional",
	exp: "Expired",
	pc: "Price Change",
};

const getStatusFullForm = (status: string): string => {
	return STATUS_MAP[status.toLowerCase()] || status;
};

const PropertyInfoComponent = ({
	info,
	isLast,
}: PropertyInfoComponentProps) => {
	const fullStatus = getStatusFullForm(info.status);
	const navigate = useNavigate();
	const { isWebview } = useAuth();
	const { setShowLoginModal } = useShowLoginModalStore();

	return (
		<div className="history-item">
			<div
				className="flex justify-between w-full items-center py-2 relative"
				onClick={() => {
					if (isWebview) {
						setShowLoginModal(true);
					}
				}}
				onKeyDown={() => {
					if (isWebview) {
						setShowLoginModal(true);
					}
				}}
			>
				{isWebview &&
				(info.status === "Sld" ||
					info.status === "Lsd" ||
					info.status === "Sc" ||
					info.status === "Ter" ||
					info.status === "Sus" ||
					info.status === "Exp") ? (
					<>
						<div className="absolute inset-0 flex items-center justify-center z-10">
							<span className="text-white text-xs font-medium bg-z-dark-moss-green rounded-md px-2 py-2 shadow-md drop-shadow-md">
								Login to view details
							</span>
						</div>
						<div className="blur">
							<div className="text-black text-lg font-semibold">Login</div>
							<div className="text-[#A1A09F] text-sm">
								{formatDate(info.date, "date")}
							</div>
						</div>
						<div className="flex flex-col items-end blur">
							<div className="text-center rounded font-semibold uppercase text-sm w-full px-2 py-1 bg-[#E7FFF3] text-[#0A9D57]/10">
								Login
							</div>
						</div>
						<div className="flex flex-col items-end blur">
							{info.listingId && (
								<button
									type="button"
									className="text-white text-sm bg-z-dark-moss-green rounded-[4px] px-4 py-1"
								>
									View
								</button>
							)}
							<div className="text-black text-sm">Login</div>
						</div>
					</>
				) : (
					<>
						<div>
							<div className="text-black text-lg font-semibold">
								${formatPrice(convertToFormattedAmount(info.listingPrice))}
							</div>
							<div className="text-[#A1A09F] text-sm">
								{formatDate(info.date, "date")}
							</div>
						</div>
						<div className="flex flex-col items-end">
							<div className="text-center rounded font-semibold uppercase text-sm w-full px-2 py-1 bg-[#E7FFF3] text-[#0A9D57]">
								{fullStatus}
							</div>
						</div>
						<div className="flex flex-col items-end">
							{info.listingId && (
								<button
									type="button"
									className="text-white text-sm bg-z-dark-moss-green rounded-[4px] px-4 py-1"
									onClick={() => navigate(`/listing/${info.listingId}`)}
								>
									View
								</button>
							)}
							<div className="text-black text-sm">{info.mlsNumber}</div>
						</div>
					</>
				)}
			</div>
			{!isLast && <hr className="h-px bg-[#F3F3F3]" />}
		</div>
	);
};

interface ListingHistoryProps {
	listingHistory: PropertyInfoProps[];
}

const ListingHistory = ({ listingHistory }: ListingHistoryProps) => {
	const [showMore, setShowMore] = useState(false);
	const contentRef = useRef<HTMLDivElement | null>(null);
	const initialHeightRef = useRef<number>(0);
	const itemsToShowInitially = 4;

	const sortedListingHistory = [...listingHistory].sort((a, b) =>
		moment(b.date).diff(moment(a.date)),
	);

	useEffect(() => {
		if (contentRef.current && !initialHeightRef.current) {
			const initialItems =
				contentRef.current.querySelectorAll<HTMLDivElement>(".history-item");
			const initialHeight = Array.from(initialItems)
				.slice(0, itemsToShowInitially)
				.reduce((acc, item) => acc + item.clientHeight, 0);
			initialHeightRef.current = initialHeight;
			contentRef.current.style.maxHeight = `${initialHeight}px`;
		}
	}, []);

	useEffect(() => {
		if (contentRef.current) {
			contentRef.current.style.maxHeight = showMore
				? `${contentRef.current.scrollHeight}px`
				: `${initialHeightRef.current}px`;
		}
	}, [showMore]);

	return (
		<div className="w-full px-4">
			<div className="text-black font-semibold text-lg">Listing History</div>
			<div
				ref={contentRef}
				className="overflow-hidden transition-max-height duration-300 ease-in-out"
			>
				{sortedListingHistory.map((info, index) => {
					const isLast = showMore
						? index === sortedListingHistory.length - 1
						: index === itemsToShowInitially - 1 ||
							index === sortedListingHistory.length - 1;
					return (
						<PropertyInfoComponent
							key={info.mlsNumber}
							info={info}
							isLast={isLast}
						/>
					);
				})}
			</div>
			{sortedListingHistory.length > itemsToShowInitially && (
				<button
					type="button"
					className="text-z-dark-moss-green font-semibold flex justify-center gap-2 items-center cursor-pointer mt-2 text-center w-full"
					onClick={() => setShowMore(!showMore)}
				>
					{showMore ? "Show less" : "Show more"}
					<img
						src={GreenArrowDownIcon}
						alt="Arrow Down"
						className={`transform transition-transform duration-300 ${
							showMore ? "rotate-180" : ""
						}`}
					/>
				</button>
			)}
		</div>
	);
};

export { ListingHistory };
