import { useNavigate } from "react-router-dom";
import { LoadingCircle } from "../common/loading";
import { openLink } from "../../services/openLink";
import { initiateAuth } from "../../services/auth";
import { useAuthStore } from "../../stores/useAuthStore";
import { isValidEmail } from "../../utils/emailValidator";
// import AppleIcon from "../../assets/SocialMedia/appleIcon.svg";
// import GoogleIcon from "../../assets/SocialMedia/googleIcon.svg";
import { useVerificationStore } from "../../stores/verificationStore";
// import FaceBookIcon from "../../assets/SocialMedia/facebookIcon.svg";
import {
	useState,
	useEffect,
	useRef,
	useCallback,
	type ChangeEvent,
} from "react";

const LoginEmailView = () => {
	const [localEmail, setLocalEmail] = useState<string>("");
	const [countdown, setCountdown] = useState(0);
	const { setAuthEmail } = useAuthStore();
	const [isValid, setIsValid] = useState(true);
	const [loading, setLoading] = useState(false);
	const { verificationFailed, setVerificationFailed } = useVerificationStore();
	const [agreementChecked, setAgreementChecked] = useState(false);
	const onboardingButtonRef = useRef<HTMLButtonElement>(null);
	const navigate = useNavigate();

	const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
		const lowercaseEmail = e.target.value.toLowerCase();
		setLocalEmail(lowercaseEmail);
		setIsValid(true);
	};

	const validateEmail = async () => {
		const emailIsValid = isValidEmail(localEmail);
		setIsValid(emailIsValid);
		if (emailIsValid) {
			try {
				setLoading(true);
				await initiateAuth(localEmail);
				setLoading(false);
			} catch (error) {
				console.error("Failed to send verification email:", error);
				setVerificationFailed(true);
				setLoading(false);
			}
		}
		return emailIsValid;
	};

	useEffect(() => {
		if (verificationFailed) {
			setCountdown(10);
			const timer = setInterval(() => {
				setCountdown((prev) => {
					if (prev === 1) {
						clearInterval(timer);
						setVerificationFailed(false);
						return 0;
					}
					return prev - 1;
				});
			}, 1000);
			return () => clearInterval(timer);
		}
	}, [verificationFailed, setVerificationFailed]);

	const handleKeyDown = useCallback((e: KeyboardEvent) => {
		if (e.key === "Enter") {
			if (onboardingButtonRef.current) {
				onboardingButtonRef.current.click();
			}
		}
	}, []);

	useEffect(() => {
		window.addEventListener("keydown", handleKeyDown);
		return () => {
			window.removeEventListener("keydown", handleKeyDown);
		};
	}, [handleKeyDown]);

	const handleClick = async () => {
		if (validateEmail) {
			const isValid = await validateEmail();
			if (isValid) {
				navigate("/login/verify-view");
				setAuthEmail(localEmail);
			}
		} else {
			navigate("/login/verify-view");
			setAuthEmail(localEmail);
		}
	};

	return (
		<div className="flex flex-col items-center justify-center h-full space-y-4 pt-8  relative">
			{loading && (
				<div className="flex justify-center items-center">
					<LoadingCircle />
				</div>
			)}
			{!loading && (
				<>
					<div className="w-full space-y-6">
						<div className="flex flex-col w-full space-y-2">
							<div className="font-semibold text-lg">Email Address</div>
							<div className="w-full flex flex-col">
								<input
									type="email"
									spellCheck="false"
									placeholder="Enter email address"
									autoComplete="off"
									value={localEmail}
									onChange={handleEmailChange}
									className={`border-2 rounded-full h-[63px] py-4 px-3 bg-z-dark-moss-green/5 placeholder:text-[#7C7C7C] ${
										isValid ? "border-z-dark-moss-green" : "border-red-500"
									}`}
								/>
								{!isValid && (
									<div className="text-red-500 text-left mt-2">
										Please enter a valid email address.
									</div>
								)}
							</div>
						</div>
						<div className="flex items-center">
							<input
								id="default-checkbox"
								type="checkbox"
								checked={agreementChecked}
								onChange={(e: ChangeEvent<HTMLInputElement>) =>
									setAgreementChecked(e.target.checked)
								}
								className="w-[30px] h-[30px] rounded-[20px] text-z-dark-moss-green bg-[#F9F9F9] border-[#A4A4A4]"
							/>
							<label
								htmlFor="default-checkbox"
								className="ms-2 text-sm font-medium h-full text-[#32302F]"
							>
								I agree to the{" "}
								<button
									type="button"
									onClick={() => openLink("https://zown.ca/terms-and-conditions")}
									className="underline"
								>
									terms and conditions
								</button>{" "}
								and{" "}
								<button
									onClick={() => openLink("https://zown.ca/privacy-policy")}
									type="button"
									className="underline"
								>
									privacy policy.
								</button>
							</label>
						</div>

						<button
							ref={onboardingButtonRef}
							type="button"
							className="bg-z-dark-moss-green text-white disabled:bg-[#E0E0E0] disabled:text-[#939393] w-full py-4 rounded-full font-bold shadow-md"
							onClick={handleClick}
							disabled={countdown > 0 || !agreementChecked}
						>
							{`Send Magic Link${countdown > 0 ? ` (${countdown})` : ""}`}
						</button>
					</div>
					{/* <div className="flex w-full gap-2 items-center text-center">
            <hr className="h-px my-8 bg-black 0 border-0 w-full" />
            <div className="text-sm font-medium w-full">Or Login with</div>
            <hr className="h-px my-8 bg-black border-0 w-full" />
          </div>
          <div className="w-full flex items-center justify-center h-[60px] gap-3">
            <button
              type="button"
              className="w-full flex justify-center border-[#EBEBEB] border-2 rounded-full items-center h-full"
            >
              <img src={FaceBookIcon} alt="" />
            </button>
            <button
              type="button"
              className="w-full flex justify-center border-[#EBEBEB] border-2 rounded-full items-center h-full"
            >
              <img src={GoogleIcon} alt="" />
            </button>
            <button
              type="button"
              className="w-full flex justify-center border-[#EBEBEB] border-2 rounded-full items-center h-full"
            >
              <img src={AppleIcon} alt="" />
            </button>
          </div> */}
				</>
			)}
		</div>
	);
};

export { LoginEmailView };
