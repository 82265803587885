/**
 * Capitalizes the first letter of each word in a string.
 *
 * This function takes a string as input and converts the first letter of each word to uppercase,
 * leaving the rest of the word unchanged.
 *
 * @param {string} str - The input string to capitalize.
 * @returns {string} - The resulting string with the first letter of each word capitalized.
 *
 * @example
 * // Returns 'Hello World'
 * capitalizeFirstLetter('hello world');
 */
export const capitalizeFirstLetter = (
  str: string | null | undefined
): string => {
  if (!str) {
    return ""; // Return an empty string if the input is falsy
  }
  return str
    .split(" ") // Split the string into words by spaces
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize each word
    .join(" "); // Join the words back into a single string with spaces
};
