import { useEffect } from "react";
import { useAuth } from "../context/authProvider.tsx";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";

const AuthLayout = ({ type }: { type: "public" | "private" }) => {
	const { authenticated, isWebview } = useAuth();
	const location = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		const searchParams = new URLSearchParams(window.location.search);
		const zlid = searchParams.get("zlid");

		if (zlid) {
			const appPath = `?zlid=${zlid}`;
			const currentUrl = `${window.location.origin}${window.location.pathname}${appPath}`;

			if (window.location.href !== currentUrl) {
				window.history.replaceState({}, "", currentUrl);
			}

			if (!window.location.pathname.startsWith(`/listing/${zlid}`)) {
				navigate(`/listing/${zlid}`, { replace: true });
			}
		}
	}, [navigate]);

	useEffect(() => {
		if (location.pathname.startsWith("/listing/")) {
			const zlidMatch = location.pathname.match(/\/listing\/([^/]+)/);
			const pathParts = window.location.pathname.split("/").filter(Boolean);
			const firstPart = pathParts.length > 0 ? pathParts[0] : null;

			if (zlidMatch) {
				const zlid = zlidMatch[1];
				const basePath = `?zlid=${zlid}`;
				let fullUrl;

				if (firstPart && firstPart !== "listing") {
					fullUrl = `${window.location.origin}/${firstPart}/${basePath}`;
				} else {
					fullUrl = `${window.location.origin}${basePath}`;
				}

				if (window.location.href !== fullUrl) {
					window.history.replaceState({}, "", fullUrl);
				}
			}
		}
	}, [location.pathname]);

	useEffect(() => {
		const currentUrl = window.location.href;
		if (currentUrl !== "/explore") {
			const pathParts = window.location.pathname.split("/").filter(Boolean);
			const firstPart = pathParts.length > 0 ? pathParts[0] : null;

			let fullUrl;
			if (firstPart && firstPart !== "listing") {
				fullUrl = `${window.location.origin}/${firstPart}/`;
			} else {
				fullUrl = `${window.location.origin}`;
			}
			if (window.location.href !== fullUrl && !isWebview) {
				window.history.replaceState({}, "", fullUrl);
			}
		}
	}, [isWebview]);

	useEffect(() => {
		console.log("Current route:", location.pathname);
	}, [location]);

	if (type === "public" && !isWebview) {
		return authenticated ? <Navigate to="/" /> : <Outlet />;
	}
	if (type === "public" && isWebview) {
		return authenticated ? <Navigate to="/explore" /> : <Outlet />;
	}

	if (type === "private") {
		if (window?.webkit && !isWebview) {
			return authenticated ? <Outlet /> : <Navigate to="/login" />;
		}

		return authenticated ? <Outlet /> : <Navigate to="/login/intro" />;
	}

	return null;
};

export { AuthLayout };
