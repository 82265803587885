import { useNavigate } from "react-router-dom";
import { useStep } from "../../stores/buyerHomeFeaturesStore";
import ArrowRightBlack from "../../assets/arrowRightBlack.svg";
import { useFilterPanelStore } from "../../stores/filterStore";
import { useUserProfileStore } from "../../stores/profileStore";
import { openLink, openNotification } from "../../services/openLink";

interface ProfileItemProps {
	imageSrc: string;
	title: string;
	description: string;
	link?: string;
}

interface ProfileSectionProps {
	title: string;
	items: ProfileItemProps[];
}

const ProfileItem = ({
	imageSrc,
	title,
	description,
	link,
}: ProfileItemProps) => {
	const navigate = useNavigate();
	const { setCurrentStep } = useStep();

	const { showFilterPanel, setFilterPanelOrigin } = useFilterPanelStore();
	const { userProfile } = useUserProfileStore();

	const handleItemClick = () => {
		if (title === "Notifications") {
			openNotification();
		} else if (title === "Privacy Policy") {
			openLink("https://zown.ca/privacy-policy");
		} else if (title === "Terms & Conditions") {
			openLink("https://zown.ca/terms-and-conditions");
		} else if (title === "Chat with us") {
			openLink(
				"https://wa.me/12898166416?text=Hello,%20I%20am%20looking%20for%20assistance%20with%20the%20Zown%20mobile%20app.%20Can%20you%20help%20me%20with%20this",
			);
		} else if (title === "Get pre-approved") {
			openLink(
				"https://mortgages.zown.ca",
			);
		} else if (title === "Your matching preferences") {
			if (userProfile?.matchingPreferences?.filters?.propertyType) {
				setFilterPanelOrigin("matching");
				showFilterPanel();
			} else {
				navigate("/buyer-input-flow/max-affordability");
				setCurrentStep(5);
			}
		} else if (link) {
			navigate(link);
		} else {
			console.warn("No link provided for this item");
		}
	};

	return (
		<button
			type="button"
			className="flex items-center text-start justify-between w-full gap-1 bg-white px-4 py-2.5 rounded-[15px] shadow-sm drop-shadow"
			onClick={handleItemClick}
		>
			<div className="flex items-center gap-4">
				<div>
					<img
						src={imageSrc}
						className="w-[40px] h-[40px] rounded-full"
						alt={title}
					/>
				</div>
				<div>
					<div className="text-[14px] font-semibold">{title}</div>
					<div className="text-sm">{description}</div>
				</div>
			</div>
			<div>
				<img src={ArrowRightBlack} alt="Arrow Right" />
			</div>
		</button>
	);
};

export const ProfileSection = ({ title, items }: ProfileSectionProps) => {
	return (
		<div className="w-full space-y-4">
			<div className="font-bold">{title}</div>
			{items.map((item) => (
				<ProfileItem
					key={item.title}
					imageSrc={item.imageSrc}
					title={item.title}
					description={item.description}
					link={item.link}
				/>
			))}
		</div>
	);
};
