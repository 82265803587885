import clsx from "clsx";
import { useEffect, useState } from "react";
import { PhoneValidator } from "../utils/phoneValidator";
import { updatePreferences } from "../services/preferences";
import { useInputFlowStore } from "../stores/inputFlowStore";
import { Button, LoadingCircle } from "../components/common";
import { useUserProfileStore } from "../stores/profileStore";
import type { MainProfile } from "../interfaces/mainProfile";
import { useMatchFiltersStore } from "../stores/filterStore";
import { ProgressBar } from "../components/common/progressBar";
import { capitalizeFirstLetter } from "../utils/capitalizeLetter";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { usePropertyCityStore } from "../stores/propertyCitySelection";
import { convertToNumberAndMultiplyBy100 } from "../utils/convertAmount";
import { usePropertySearchingStore } from "../stores/propertySearchingStore";
// import { usePointsInterest } from "../components/PointsInterest/hook/pointsInterest";
// import { BuyerMatchingPreferencesPoiTypeEnum } from "../interfaces/buyerMatchingPreferences";
import {
	// useHomeFeaturesStore,
	useStep,
} from "../stores/buyerHomeFeaturesStore";
import {
	fetchAccountData,
	updateAccountData,
	updateFinancesData,
} from "../services/account";

const PROGRESS_STEP_MAP: { [key: number]: number } = {
	5: 20,
	6: 40,
	7: 60,
	9: 80,
	10: 10,
	// 11: 74,
	// 12: 86,
	// 13: 100,
};
const INDICES_STEPS = Object.keys(PROGRESS_STEP_MAP).map(Number);

const BuyerInputFlow = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const [progressBarHeight, setProgressBarHeight] = useState(0);
	const [footerHeight, setFooterHeight] = useState(0);
	const { currentStep, setCurrentStep } = useStep();
	const showProgressBar = INDICES_STEPS.includes(currentStep);
	const isAffordability = [4].includes(currentStep);
	// const isPropertyMatch = [8].includes(currentStep);
	const number = PROGRESS_STEP_MAP[currentStep] || 0;
	const dynamicPaddingTop = showProgressBar ? `${progressBarHeight}px` : "30px";
	const [buttonDisabled, setButtonDisabled] = useState(false);
	const dynamicPaddingBottom = `${footerHeight}px`;
	const { selectedCities } = usePropertyCityStore();
	const { setUserProfile, userProfile } = useUserProfileStore();
	const { matchBedCount, matchBathCount, matchParking, matchDenCount } =
		useMatchFiltersStore();
	const [loading, setLoading] = useState<boolean>(false);
	const { mustHaves, niceHaves, selectedLifestyles, selectedProperties } =
		usePropertySearchingStore();
	const {
		debt,
		savings,
		income,
		firstName,
		lastName,
		phoneNumber,
		validateDetailsInputForm,
	} = useInputFlowStore();
	// const {
	// 	selectedAddresses,

	// 	placeWorship,
	// 	selectedStation,
	// 	selectedSchool,
	// 	selectedWorkAddress,
	// } = usePointsInterest();
	// const {
	// 	selectedStationLat,
	// 	selectedStationLon,
	// 	selectedSchoolLat,
	// 	selectedSchoolLon,
	// 	selectedWorkLat,
	// 	selectedWorkLon,
	// } = useHomeFeaturesStore();

	const handleNextStep = async () => {
		const accountPayload: Partial<MainProfile> = {};
		switch (location.pathname) {
			case "/buyer-input-flow/details-input": {
				validateDetailsInputForm();
				if (firstName) {
					accountPayload.firstName = capitalizeFirstLetter(firstName);
				}
				if (lastName) {
					accountPayload.lastName = capitalizeFirstLetter(lastName);
				}
				if (phoneNumber) {
					let formattedPhoneNumber: string | undefined;
					try {
						// Try validating and formatting as CA first, fallback to US if invalid
						if (PhoneValidator.isValid(phoneNumber, "CA")) {
							formattedPhoneNumber = PhoneValidator.formatE164(
								phoneNumber,
								"CA",
							);
						} else if (PhoneValidator.isValid(phoneNumber, "US")) {
							formattedPhoneNumber = PhoneValidator.formatE164(
								phoneNumber,
								"US",
							);
						} else {
							throw new Error("Invalid phone number format");
						}
						accountPayload.phoneNumber = formattedPhoneNumber;
					} catch (error) {
						console.error("Error formatting phone number:", error);
						accountPayload.phoneNumber = ""; // Or handle error feedback to user
					}
				}

				try {
					setButtonDisabled(true);

					if (Object.keys(accountPayload).length > 0) {
						const accountResponse = await updateAccountData(accountPayload);
						setUserProfile(accountResponse);
					}
					setButtonDisabled(false);
					navigate("/buyer-input-flow/annual-income");
				} catch (error) {
					console.error("Error updating data:", error);
					setButtonDisabled(false);
				}

				break;
			}
			case "/buyer-input-flow/annual-income": {
				const financesIncomePayload = {
					totalIncome: {
						amount: convertToNumberAndMultiplyBy100(income),
						currency: "CAD",
					},
				};
				try {
					setButtonDisabled(true);
					const financesResponse = await updateFinancesData(
						financesIncomePayload,
					);
					setUserProfile(financesResponse);
					setButtonDisabled(false);
					navigate("/buyer-input-flow/saving");
				} catch (error) {
					console.error("Error updating income data:", error);
					setButtonDisabled(false);
				}
				break;
			}
			case "/buyer-input-flow/saving": {
				const financesSavingPayload = {
					totalSavings: {
						amount: convertToNumberAndMultiplyBy100(savings),
						currency: "CAD",
					},
				};
				try {
					setButtonDisabled(true);
					const financesResponse = await updateFinancesData(
						financesSavingPayload,
					);
					setUserProfile(financesResponse);
					setButtonDisabled(false);
					navigate("/buyer-input-flow/monthly-debt");
				} catch (error) {
					console.error("Error updating savings data:", error);
					setButtonDisabled(false);
				}
				break;
			}
			case "/buyer-input-flow/monthly-debt": {
				setCurrentStep(4);
				const financesDebtPayload = {
					totalMonthlyDebts: {
						amount: convertToNumberAndMultiplyBy100(debt),
						currency: "CAD",
					},
				};
				try {
					setButtonDisabled(true);
					const financesResponse =
						await updateFinancesData(financesDebtPayload);
					setUserProfile(financesResponse);
					setButtonDisabled(false);
					navigate("/buyer-input-flow/affordability");
				} catch (error) {
					console.error("Error updating debt data:", error);
					setButtonDisabled(false);
				}
				break;
			}
			case "/buyer-input-flow/affordability": {
				setCurrentStep(5);
				navigate("/");
				break;
			}
			case "/buyer-input-flow/max-affordability": {
				setCurrentStep(6);
				navigate("/buyer-input-flow/property-preferences");
				break;
			}
			case "/buyer-input-flow/property-preferences": {
				const mapValue = (value: string) =>
					value === "5+" ? 5 : Number(value);

				const numberOfBedrooms =
					matchBedCount && matchBedCount.length > 0
						? matchBedCount.every((value) => value === "Any")
							? null
							: matchBedCount.map(mapValue)
						: undefined;

				const numberOfBathrooms =
					matchBathCount && matchBathCount.length > 0
						? matchBathCount.every((value) => value === "Any")
							? null
							: matchBathCount.map(mapValue)
						: undefined;

				const numberOfParkingSpaces =
					matchParking && matchParking.length > 0
						? matchParking.every((value) => value === "Any")
							? null
							: matchParking.map(mapValue)
						: undefined;

				const mappedFilters = {
					numberOfBedrooms,
					numberOfBathrooms,
					numberOfParkingSpaces,
					dens: matchDenCount !== null ? matchDenCount : null,
				};

				try {
					setButtonDisabled(true);
					await updatePreferences(undefined, undefined, mappedFilters);
					setButtonDisabled(false);
					setCurrentStep(7);
					navigate("/buyer-input-flow/property-searching");
				} catch (error) {
					console.error("Error updating preferences:", error);
					setButtonDisabled(false);
				}
				setButtonDisabled(false);
				break;
			}
			case "/buyer-input-flow/property-searching": {
				const mappedFilters = {
					propertyType:
						selectedProperties && selectedProperties.length > 0
							? selectedProperties
							: undefined,
				};
				try {
					setButtonDisabled(true);

					await updatePreferences(undefined, undefined, mappedFilters);
					setButtonDisabled(false);
					setCurrentStep(8);
					navigate("/buyer-input-flow/city-selection");
				} catch (error) {
					console.error("Error updating preferences:", error);
					setButtonDisabled(false);
				}

				break;
			}
			case "/buyer-input-flow/city-selection": {
				const mappedFilters = {
					selectedCities:
						selectedCities && selectedCities.length > 0
							? selectedCities
							: undefined,
				};
				try {
					setButtonDisabled(true);

					await updatePreferences(undefined, undefined, mappedFilters);

					if (userProfile?.matchingPreferences) {
						setLoading(true);
						const accountData = await fetchAccountData();
						if (accountData) {
							setUserProfile(accountData);
						}
						setLoading(false);
						setButtonDisabled(false);
						navigate("/");
					} else {
						setButtonDisabled(false);
						setCurrentStep(9);
						navigate("/buyer-input-flow/property-match");
					}
				} catch (error) {
					console.error("Error updating preferences:", error);
					setButtonDisabled(false);
				}

				break;
			}
			case "/buyer-input-flow/property-match": {
				try {
					setButtonDisabled(true);
					setLoading(true);
					const accountData = await fetchAccountData();
					if (accountData) {
						setUserProfile(accountData);
					}

					// setCurrentStep(10);
					window
						._sendEvent("ZownAppBuyerForm", {
							Step: 6,
						})
						.catch((error: unknown) => {
							console.error(error);
						})
						.finally(() => {
							setLoading(false);
							setButtonDisabled(false);
							navigate("/");
						});
				} catch (error) {
					console.error("Error updating preferences:", error);
					setButtonDisabled(false);
				}
				break;
			}
			// case "/buyer-input-flow/points-interest": {
			// 	setCurrentStep(11);
			// 	const pointsOfInterests = [];

			// 	if (selectedSchool && selectedSchoolLat && selectedSchoolLon) {
			// 		pointsOfInterests.push({
			// 			designation: selectedSchool,
			// 			location: {
			// 				type: "Point",
			// 				coordinates: [selectedSchoolLon, selectedSchoolLat],
			// 			},
			// 			type: BuyerMatchingPreferencesPoiTypeEnum.School,
			// 		});
			// 	}

			// 	if (selectedStation && selectedStationLat && selectedStationLon) {
			// 		pointsOfInterests.push({
			// 			designation: selectedStation,
			// 			location: {
			// 				type: "Point",
			// 				coordinates: [selectedStationLon, selectedStationLat],
			// 			},
			// 			type: BuyerMatchingPreferencesPoiTypeEnum.PublicTransit,
			// 		});
			// 	}

			// 	if (selectedWorkAddress && selectedWorkLat && selectedWorkLon) {
			// 		pointsOfInterests.push({
			// 			designation: selectedWorkAddress,
			// 			location: {
			// 				type: "Point",
			// 				coordinates: [selectedWorkLon, selectedWorkLat],
			// 			},
			// 			type: BuyerMatchingPreferencesPoiTypeEnum.Work,
			// 		});
			// 	}

			// 	if (placeWorship) {
			// 		pointsOfInterests.push({
			// 			designation: placeWorship,
			// 			type: BuyerMatchingPreferencesPoiTypeEnum.PlaceOfWorship,
			// 		});
			// 	}

			// 	for (const address of selectedAddresses) {
			// 		pointsOfInterests.push({
			// 			designation: address.name,
			// 			location: {
			// 				type: "Point",
			// 				coordinates: [address.longitude, address.latitude],
			// 			},
			// 			type: BuyerMatchingPreferencesPoiTypeEnum.Other,
			// 		});
			// 	}

			// 	try {
			// 		setButtonDisabled(true);
			// 		await updatePreferences(pointsOfInterests, undefined, undefined);
			// 		setButtonDisabled(false);
			// 	} catch (error) {
			// 		console.error("Error updating preferences:", error);
			// 		setButtonDisabled(false);
			// 	}

			// 	navigate("/buyer-input-flow/lifestyle");
			// 	break;
			// }
			// case "/buyer-input-flow/lifestyle": {
			// 	setCurrentStep(12);
			// 	try {
			// 		setButtonDisabled(true);
			// 		await updatePreferences(
			// 			undefined,
			// 			undefined,
			// 			undefined,
			// 			undefined,
			// 			undefined,
			// 			selectedLifestyles,
			// 		);
			// 		setButtonDisabled(false);
			// 	} catch (error) {
			// 		console.error("Error updating preferences:", error);
			// 		setButtonDisabled(false);
			// 	}
			// 	navigate("/buyer-input-flow/needs-wants");
			// 	break;
			// }
			// case "/buyer-input-flow/needs-wants": {
			// 	try {
			// 		setButtonDisabled(true);
			// 		await updatePreferences(
			// 			undefined,
			// 			undefined,
			// 			undefined,
			// 			mustHaves,
			// 			niceHaves,
			// 		);
			// 		setButtonDisabled(false);
			// 	} catch (error) {
			// 		console.error("Error updating preferences:", error);
			// 		setButtonDisabled(false);
			// 	}
			// 	setCurrentStep(13);

			// 	navigate("/buyer-input-flow/priorities");
			// 	break;
			// }
			// case "/buyer-input-flow/priorities": {
			// 	setCurrentStep(14);
			// 	try {
			// 		setButtonDisabled(true);
			// 		await updatePreferences(undefined, niceHaves);
			// 		setButtonDisabled(false);
			// 	} catch (error) {
			// 		console.error("Error updating preferences:", error);
			// 		setButtonDisabled(false);
			// 	}
			// 	navigate("/");
			// 	break;
			// }
			default:
				break;
		}
	};

	const handlePreviousStep = () => {
		switch (location.pathname) {
			case "/buyer-input-flow/priorities":
				setCurrentStep(12);
				break;
			case "/buyer-input-flow/needs-wants":
				setCurrentStep(11);
				break;
			case "/buyer-input-flow/lifestyle":
				setCurrentStep(10);
				break;
			case "/buyer-input-flow/points-interest":
				setCurrentStep(9);
				break;
			case "/buyer-input-flow/property-match":
				setCurrentStep(8);
				break;
			case "/buyer-input-flow/city-selection":
				setCurrentStep(7);
				break;
			case "/buyer-input-flow/property-searching":
				setCurrentStep(6);
				break;
			case "/buyer-input-flow/property-preferences":
				setCurrentStep(5);
				break;
			case "/buyer-input-flow/max-affordability":
				setCurrentStep(4);
				break;
			case "/buyer-input-flow/affordability":
				setCurrentStep(3);
				break;
			case "/buyer-input-flow/monthly-debt":
				setCurrentStep(2);
				break;
			case "/buyer-input-flow/annual-income":
				setCurrentStep(1);
				break;
			case "/buyer-input-flow/saving":
				break;
			case "/buyer-input-flow/details-input":
				break;
			default:
				break;
		}
		navigate(-1);
	};

	const dynamicNextButton = () => {
		if (isAffordability) {
			return "Continue";
		}
		// if (isPropertyMatch) {
		// 	return "Get Started";
		// }
		return "Next";
	};

	useEffect(() => {
		const progressBarEl = document.getElementById("progress-bar");
		const footerEl = document.getElementById("footer");

		if (progressBarEl) {
			setProgressBarHeight(progressBarEl.offsetHeight);
		}
		if (footerEl) {
			setFooterHeight(footerEl.offsetHeight);
		}
	}, []);

	return (
		<div
			className={clsx(" overflow-auto absolute  inset-0")}
			style={{
				paddingTop: dynamicPaddingTop,
				paddingBottom: dynamicPaddingBottom,
			}}
		>
			{showProgressBar && (
				<div
					id="progress-bar"
					className={`fixed ${
						window.bridge ? "top-bridge-inset-top" : "top-0"
					}  bg-white h-20 w-full z-10`}
				>
					<ProgressBar totalSteps={number} />
				</div>
			)}
			<div
				className={clsx(
					"relative flex justify-start h-full overflow-auto items-center pt-bridge-inset-top pb-bridge-inset-bottom",
					// isPropertyMatch && "!mx-0",
					!showProgressBar && "h-full",
				)}
			>
				{loading && (
					<div className="flex items-center justify-center h-full w-full">
						<LoadingCircle />
					</div>
				)}
				{!loading && <Outlet />}
			</div>
			<div
				id="footer"
				className={`fixed bottom-0 right-0 left-0 flex items-center ${
					window.bridge ? "pb-bridge-inset-bottom pt-3" : "py-4"
				} justify-between px-4 bg-white border-t border-[#F2F2F2] w-full`}
			>
				{currentStep !== 4 ? (
					<Button
						onClick={handlePreviousStep}
						disable={buttonDisabled}
						variant="primary"
						customClassName="font-bold text-lg bg-white"
						height="lg"
						isOutlined={true}
						width="120px"
						radius="60px"
					>
						Back
					</Button>
				) : (
					<div />
				)}
				{buttonDisabled && !loading && (
					<div className="pr-10 pb-5">
						<LoadingCircle height={40} width={40} />
					</div>
				)}
				{!buttonDisabled && (
					<Button
						onClick={handleNextStep}
						variant="primary"
						customClassName="font-bold text-lg min-w-[120px] shadow-md flex flex "
						height="lg"
						width="fit"
						radius="60px"
						disable={
							(currentStep === 0 && !(firstName && lastName && phoneNumber)) ||
							(currentStep === 1 && !income) ||
							(currentStep === 7 && selectedProperties.length === 0) ||
							(currentStep === 8 && selectedCities.length === 0) ||
							(currentStep === 11 && selectedLifestyles.length < 3) ||
							(currentStep === 12 &&
								(mustHaves.length === 0 || niceHaves.length === 0))
						}
					>
						{dynamicNextButton()}
					</Button>
				)}
			</div>
		</div>
	);
};

export { BuyerInputFlow };
