import { FaPlus } from "react-icons/fa";
import { FaMinus } from "react-icons/fa";
import { FaSearch } from "react-icons/fa";
import { SelectedCitiesEnum } from "../../selectedCities";
import { useLocation, useNavigate } from "react-router-dom";
import { updatePreferences } from "../../services/preferences";
import { useUserProfileStore } from "../../stores/profileStore";
import { useCallback, useEffect, useRef, useState } from "react";
import { usePropertyCityStore } from "../../stores/propertyCitySelection";

interface CityChipProps {
	label: string;
	searchTerm?: string;
	onClick: (label: string) => void;
}

const SearchSelector = ({ label, searchTerm, onClick }: CityChipProps) => {
	const highlightMatchingText = (text: string, search: string | undefined) => {
		const searchTerm = typeof search === "string" ? search : "";

		if (!searchTerm.trim()) {
			return text;
		}

		const escapedSearch = searchTerm.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
		const regex = new RegExp(`(${escapedSearch})`, "gi");
		const parts = text.split(regex);

		return parts.map((part, index) =>
			regex.test(part) ? (
				<span key={`${label}-${index}`} className="font-extrabold text-black">
					{part}
				</span>
			) : (
				<span className="font-normal" key={`${label}-${index}`}>
					{part}
				</span>
			),
		);
	};

	return (
		<div
			className="flex m-2 border-b overflow-hidden font-medium py-3 text-sm cursor-pointer"
			onClick={() => onClick(label)}
			onKeyDown={() => onClick(label)}
		>
			<div className="flex items-center pr-2">
				<FaPlus className="text-sm" />
			</div>
			<div className="flex-1 flex items-center text-black">
				{highlightMatchingText(label, searchTerm)}
			</div>
		</div>
	);
};

interface CityChipProps {
	label: string;
	searchTerm?: string;
	onClick: (label: string) => void;
}
const recommendedCities: SelectedCitiesEnum[] = [
	SelectedCitiesEnum.Markham,
	SelectedCitiesEnum.Toronto,
	SelectedCitiesEnum.Brampton,
	SelectedCitiesEnum.RichmondHill,
];

const testSearch = Object.values(SelectedCitiesEnum);

const CityChip = ({ label, onClick }: CityChipProps) => {
	return (
		<div
			onClick={() => onClick(label)}
			key={label}
			className="px-5 space-x-6 py-3 rounded-full bg-[#EFF6FF] flex items-center justify-between"
			onKeyDown={(e) => {
				if (e.key === "Enter" || e.key === " ") {
					e.preventDefault();
					onClick(label);
				}
			}}
		>
			<span className="text-z-shark text-sm font-semibold">{label}</span>
			<FaPlus className="text-[#32302F]" />
		</div>
	);
};

const SelectedCity = ({ label, onClick }: CityChipProps) => {
	return (
		<div
			className="flex my-2 rounded-md bg-gray-100 border overflow-hidden"
			onClick={() => {
				onClick(label);
			}}
			onKeyDown={() => {
				onClick(label);
			}}
		>
			<div className="flex-1 py-3 px-4 text-sm flex items-center text-black font-bold">
				{label}
			</div>
			<div className="flex items-center pr-2">
				<FaMinus className="text-[#32302F]" />
			</div>
		</div>
	);
};

const BuyerSearchView = () => {
	const [searchActive, setSearchActive] = useState<boolean>(false);
	const [searchTerm, setSearchTerm] = useState<string>("");
	const [remainingRecommendedCities, setRemainingRecommendedCities] =
		useState<string[]>(recommendedCities);
	const [filteredCities, setFilteredCities] = useState<string[]>([]);
	const [showError, setShowError] = useState<boolean>(false);
	const [disabledButton, setDisabledButton] = useState<boolean>(false);

	const { selectedCities, setSelectedCities, removeCity } =
		usePropertyCityStore();

	const location = useLocation();
	const origin = location.state?.origin;
	const { userProfile } = useUserProfileStore();

	const navigate = useNavigate();
	const inputRef = useRef<HTMLInputElement>(null);
	const buttonContainerRef = useRef<HTMLDivElement>(null);

	const fetchCities = (term: string): Promise<string[]> => {
		return new Promise((resolve) => {
			setTimeout(() => {
				const results = testSearch.filter((city) =>
					city.toLowerCase().includes(term.toLowerCase()),
				);
				resolve(results);
			}, 500);
		});
	};

	const handleAddCity = useCallback(
		(label: string) => {
			const selectedCity = Object.values(SelectedCitiesEnum).find(
				(city) => city === label
			) as SelectedCitiesEnum | undefined;
	
			if (selectedCity) {
				setRemainingRecommendedCities((prev) => prev.filter((item) => item !== selectedCity));
				if (origin === "matchingFilter") {
					setSelectedCities(selectedCity);
				}
			} else {
				console.warn(`City "${label}" is not a valid SelectedCitiesEnum value.`);
			}
		},
		[origin, setSelectedCities]
	);
	

	const handleRemoveCity = (label: string) => {
		const selectedCity = Object.values(SelectedCitiesEnum).find(
			(city) => city === label,
		) as SelectedCitiesEnum | undefined;

		if (selectedCity) {
			if (origin === "matchingFilter") {
				removeCity(selectedCity);
			}

			if (recommendedCities.includes(selectedCity)) {
				setRemainingRecommendedCities((prevCities) => [
					...prevCities,
					selectedCity,
				]);
			}
		} else {
			console.warn(`City "${label}" is not a valid SelectedCitiesEnum value.`);
		}
	};

	useEffect(() => {
		const userSelectedCities =
			userProfile?.matchingPreferences?.filters?.selectedCities || [];

		for (const city of userSelectedCities) {
			if (city && typeof city === "string") {
				if (origin === "matchingFilter") {
					handleAddCity(city);
				}
			}
		}
	}, [userProfile, handleAddCity, origin]);

	const handleContinue = async () => {
		if (origin === "matchingFilter" && selectedCities.length === 0) {
			setShowError(true);
			return;
		}
		setShowError(false);
		if (origin === "matchingFilter") {
			if (selectedCities) {
				const matchedEnumValues = selectedCities
					.map((city) => {
						const enumKey = Object.keys(SelectedCitiesEnum).find(
							(key) =>
								SelectedCitiesEnum[key as keyof typeof SelectedCitiesEnum] ===
								city,
						);
						return enumKey
							? SelectedCitiesEnum[enumKey as keyof typeof SelectedCitiesEnum]
							: null;
					})
					.filter((city) => city !== null);

				const mappedFilters = {
					selectedCities:
						matchedEnumValues && matchedEnumValues.length > 0
							? matchedEnumValues
							: undefined,
				};
				setDisabledButton(true);
				await updatePreferences(undefined, undefined, mappedFilters);
				setDisabledButton(false);
			}
			navigate("/profile/points-interest");
		} else {
			navigate("/explore");
		}
	};


	return (
		<div className="bg-white flex flex-col h-full relative">
			<div className="flex flex-col w-full justify-start pt-2 space-y-2 px-4">
				<div className="text-black font-bold text-2xl">
					{searchActive ? "Where" : "Pick a City"}
				</div>
				<div className="items-center w-full space-y-2">
					<div className="flex rounded-full border-2 overflow-hidden">
						{searchActive && (
							<div className="flex items-center pl-4">
								<FaSearch className="text-gray-500 text-lg" />
							</div>
						)}
						<input
							ref={inputRef}
							type="text"
							placeholder={"Enter a city"}
							className="flex-1 py-5 px-4 text-sm text-black"
							onFocus={() => setSearchActive(true)}
							onChange={(e) => {
								const value = e.target.value;
								setSearchTerm(value);
								fetchCities(value).then(setFilteredCities);
							}}
							value={searchTerm}
						/>
					</div>
					{showError && (
						<div className="text-red-500 text-sm w-full flex text-start items-start">
							You need to select a city to continue.
						</div>
					)}
				</div>
			</div>
			<div className="overflow-y-auto pb-20">
				{!searchActive && (
					<div className="px-4">
						<div className="flex flex-col w-full py-6">
							<span className="text-black font-bold text-md pb-1">
								Recommended Cities
							</span>
							<div className="flex items-center flex-wrap gap-2">
								{remainingRecommendedCities.map((label, index) => (
									<CityChip
										key={`${label}-${index}`}
										label={label}
										onClick={handleAddCity}
									/>
								))}
							</div>
						</div>

						<div className="flex flex-col w-full py-6">
							<span className="text-black font-bold text-md">
								{origin === "matchingFilter"
									? "Selected Cities"
									: "Selected City"}
							</span>
							{selectedCities.map((label, index) => (
								<SelectedCity
									key={`${label}-${index}`}
									label={label}
									onClick={handleRemoveCity}
								/>
							))}
						</div>
					</div>
				)}

				{searchActive &&
					filteredCities.length > 0 &&
					filteredCities.map((label) => (
						<SearchSelector
							key={label}
							label={label}
							searchTerm={searchTerm}
							onClick={(label) => {
								handleAddCity(label);
								setSearchTerm("");
								setSearchActive(false);
							}}
						/>
					))}
			</div>

			<div
				ref={buttonContainerRef}
				className={`absolute bottom-0 ${
					window.bridge ? "pb-bridge-inset-bottom" : "pb-4"
				} left-0 w-full h-[10vh] z-50 flex justify-center items-center`}
			>
				<button
					type={"button"}
					className="bg-z-dark-moss-green w-3/4 p-4 rounded-3xl font-bold text-white disabled:bg-[#D9D9D9]"
					onClick={handleContinue}
					disabled={disabledButton}
				>
					Continue
				</button>
			</div>
		</div>
	);
};

export { BuyerSearchView };
