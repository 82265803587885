import { debounce, groupBy } from "lodash";
import { Markers } from "./markers";
import { MapCard } from "../Card/mapCard";
import useSupercluster from "use-supercluster";
import type { PointFeature } from "supercluster";
import { Swiper, SwiperSlide } from "swiper/react";
import { useMapStore } from "../../../stores/mapCordinations";
import { useCallback, useEffect, useRef, useState } from "react";
import { useMapFiltersStore } from "../../../stores/filterMapStore";
import ReactMapGL, { type MapRef, type ViewState } from "react-map-gl";
import { selectedCitiesCoordinates } from "../../../citiesWithLatLong";
import { useFilteredPropertiesStore } from "../../../stores/exploreListings";
import { mapPropertyTypeForPost } from "../../common/FilterPanel/filterPanel";
import { convertToNumberAndMultiplyBy100 } from "../../../utils/convertAmount";
import { getDistanceFromLatLonInMeters } from "../../../utils/getDistanceFromLatLonInMeters";
import type { BuyerMatchingPreferencesPropertyTypeEnum } from "../../../interfaces/buyerMatchingPreferences";
import {
	fetchExploreListings,
	parseBasement,
	parseDaysOnMarket,
	parseGarage,
	parseNumKitchen,
	parseStyle,
	type SearchListingInterface,
	transformFilterValues,
} from "../../../services/exploreListings";

import "swiper/css";
import "mapbox-gl/dist/mapbox-gl.css";
import { useFilterPanelStore } from "../../../stores/filterStore";

interface PointProperties extends SearchListingInterface {
	cluster: false;
	propertyId: string | number;
}

const MAPBOX_TOKEN =
	"pk.eyJ1IjoiYmVhcmNvZGVzODcwIiwiYSI6ImNtMHBmMjVqZzAwZWkycXEwYmcxcHJjMGwifQ.UeHMFKKaRuaTR-NqOPPsnw";

const SearchMapView = () => {
	const [properties, setProperties] = useState<SearchListingInterface[]>([]);
	const [clickedProperties, setClickedProperties] = useState<
		SearchListingInterface[]
	>([]);
	const { setFilteredProperties, setLoading } = useFilteredPropertiesStore();
	const [markerClicked, setMarkerClicked] = useState(false);
	const [mapReady, setMapReady] = useState(false);
	const { setMapState, mapLatitude, mapLongitude, setMapGlZoom, mapGlZoom } =
		useMapStore();
	const { filterPanelVisible } = useFilterPanelStore();

	const initialViewState = {
		longitude: mapLongitude ? mapLongitude : -79.4163,
		latitude: mapLatitude ? mapLatitude : 43.70011,
		zoom: mapGlZoom ? mapGlZoom : 13,
		bearing: 0,
		pitch: 0,
		padding: {
			top: 0,
			bottom: 0,
			left: 0,
			right: 0,
		},
	};
	const [viewState, setViewState] = useState<ViewState>(initialViewState);

	const mapRef = useRef<MapRef | null>(null);
	const {
		minPrice,
		maxPrice,
		beds,
		baths,
		dens,
		parking,
		propertyType,
		numberOfKitchens,
		garageType,
		basement,
		style,
		daysOnMarket,
		mapSelectedCities,
		reloadMapCities,
		setMapSelectedCities,
		setSortFilter,
		sortFilter,
		setReloadMapCities,
	} = useMapFiltersStore();

	const fetchPropertiesBasedOnView = useCallback(async () => {
		if (mapRef.current) {
			const map = mapRef.current.getMap();
			const { lng, lat } = map.getCenter();
			const bounds = map.getBounds();
			if (!bounds) {
				return;
			}
			const northEast = bounds.getNorthEast();

			const radius = getDistanceFromLatLonInMeters(
				lat,
				lng,
				northEast.lat,
				northEast.lng,
			);
			setLoading(true);

			const fetchedProperties = await fetchExploreListings(
				"1",
				"250",
				"map",
				sortFilter.sortOrder,
				lng,
				lat,
				radius,
				undefined,
				{
					numberOfBedrooms:
						beds.length > 0 ? transformFilterValues(beds) : null,
					numberOfBathrooms:
						baths.length > 0 ? transformFilterValues(baths) : null,

					numberOfParkingSpaces:
						parking.length > 0 ? transformFilterValues(parking) : null,

					dens: dens,
					priceRange: [
						convertToNumberAndMultiplyBy100(minPrice.toString()),
						convertToNumberAndMultiplyBy100(maxPrice.toString()),
					],
					propertyType:
						propertyType.length > 0
							? propertyType
									.map(mapPropertyTypeForPost)
									.filter(
										(pt): pt is BuyerMatchingPreferencesPropertyTypeEnum =>
											pt !== null,
									)
							: null,

					numKitchens: parseNumKitchen(numberOfKitchens),
					garage: parseGarage(garageType),
					daysOnMarket: parseDaysOnMarket(daysOnMarket),
					style: parseStyle(style),
					basement: parseBasement(basement),
					selectedCities: null,
				},
			);

			if (fetchedProperties) {
				setProperties(fetchedProperties);
				setMapGlZoom(map.getZoom());
				setMapState(lat, lng, radius);
				setFilteredProperties(fetchedProperties);
				setLoading(false);
			}
		}
	}, [
		setFilteredProperties,
		minPrice,
		maxPrice,
		beds,
		baths,
		dens,
		parking,
		propertyType,
		numberOfKitchens,
		garageType,
		daysOnMarket,
		style,
		basement,
		setMapState,
		sortFilter,
		setMapGlZoom,
		setLoading,
	]);

	const handleMapLoad = useCallback(() => {
		setMapReady(true);
		moveToSelectedCity();
		setMapSelectedCities(null);
		fetchPropertiesBasedOnView();
	}, [fetchPropertiesBasedOnView, setMapSelectedCities]);

	useEffect(() => {
		if (mapRef.current) {
			setMapReady(true);
		}
	}, []);

	useEffect(() => {
		if (mapReady && !filterPanelVisible) {
			fetchPropertiesBasedOnView();
		}
	}, [mapReady, fetchPropertiesBasedOnView, filterPanelVisible]);

	useEffect(() => {
		const fetchNewProperties = async () => {
			await fetchPropertiesBasedOnView();
		};
		if (reloadMapCities && !filterPanelVisible) {
			moveToSelectedCity();
			fetchNewProperties();
			setClickedProperties([]);
			setReloadMapCities(false);
		}
	}, [
		reloadMapCities,
		setReloadMapCities,
		fetchPropertiesBasedOnView,
		filterPanelVisible,
	]);

	const moveToSelectedCity = useCallback(() => {
		if (mapSelectedCities && mapSelectedCities.length > 0) {
			const selectedCity = mapSelectedCities[0];
			const cityCoordinates = selectedCitiesCoordinates[selectedCity];

			if (
				Array.isArray(cityCoordinates) &&
				cityCoordinates.length === 2 &&
				cityCoordinates.every((coord) => coord !== null)
			) {
				const [longitude, latitude] = cityCoordinates.map(Number);
				if (
					!(Number.isNaN(longitude) || Number.isNaN(latitude)) &&
					mapRef.current
				) {
					mapRef.current.flyTo({
						center: [longitude, latitude],
						zoom: 13,
						essential: true,
					});
				}
			} else {
				console.warn(`Invalid or null coordinates for city: ${selectedCity}`);
			}
		}
	}, [mapSelectedCities]);

	const points: PointFeature<PointProperties>[] = Object.entries(
		groupBy(
			properties,
			(property) =>
				`${property?.coordinates?.long},${property?.coordinates?.lat}`,
		),
	).map(([coordinates, groupedProperties]) => {
		const [long, lat] = coordinates.split(",").map(Number);

		return {
			type: "Feature",
			properties: {
				cluster: false,
				propertyId:
					groupedProperties.length === 1
						? groupedProperties[0].listingId
						: null,
				unit: groupedProperties,
				countUnit: groupedProperties.length,
				...groupedProperties[0],
			},
			geometry: {
				type: "Point",
				coordinates: [long, lat],
			},
		};
	});

	const map = mapRef.current?.getMap();

	let bounds: [number, number, number, number] | undefined;

	if (map) {
		const mapBounds = map.getBounds();
		if (mapBounds) {
			bounds = mapBounds.toArray().flat() as [number, number, number, number];
		}
	}

	const { clusters, supercluster } = useSupercluster({
		points,
		bounds,
		zoom: viewState.zoom,
		options: { radius: 75, maxZoom: 20 },
	});

	const handleOnMove = useCallback(
		debounce((event) => {
			setViewState(event.viewState);
		}, 300),
		[],
	);

	const handleOnMoveEnd = useCallback(() => {
		if (!markerClicked) {
			setClickedProperties([]);
		}
		setMarkerClicked(false);
		fetchPropertiesBasedOnView();
	}, [fetchPropertiesBasedOnView, markerClicked]);

	const handleMarkerClick = useCallback(
		(clusterId: number | null, longitude: number, latitude: number) => {
			setMarkerClicked(true);

			if (clusterId) {
				const clusterExpansionZoom =
					supercluster?.getClusterExpansionZoom(clusterId);
				mapRef.current?.flyTo({
					center: [longitude, latitude],
					zoom: clusterExpansionZoom,
				});

				const clusteredPoints = supercluster?.getLeaves(
					clusterId,
					Number.POSITIVE_INFINITY,
				);
				if (clusteredPoints) {
					const clusteredProperties = clusteredPoints.flatMap((point) =>
						Array.isArray(point.properties.unit)
							? point.properties.unit
							: [point.properties],
					);
					setClickedProperties(clusteredProperties);
				}
			} else {
				const groupedProperties = points
					.filter(
						(point) =>
							point.geometry.coordinates[0] === longitude &&
							point.geometry.coordinates[1] === latitude,
					)
					.flatMap((point) =>
						Array.isArray(point.properties.unit)
							? point.properties.unit
							: [point.properties],
					);

				setClickedProperties(groupedProperties);
			}
		},
		[supercluster, points],
	);

	return (
		<div className="fixed inset-0">
			<ReactMapGL
				mapboxAccessToken={MAPBOX_TOKEN}
				mapStyle="mapbox://styles/mapbox/streets-v11"
				ref={mapRef}
				onLoad={handleMapLoad}
				initialViewState={initialViewState}
				onMove={handleOnMove}
				onMoveEnd={handleOnMoveEnd}
				style={{ width: "100%", height: "100%" }}
			>
				<Markers
					clusters={clusters}
					onMarkerClick={handleMarkerClick}
					supercluster={supercluster}
				/>
			</ReactMapGL>

			{clickedProperties.length > 0 && (
				<Swiper
					slidesPerView={clickedProperties.length > 1 ? 1.2 : 1}
					spaceBetween={15}
					centeredSlides={true}
					loop={false}
					grabCursor={true}
					className="absolute bottom-[260px]"
				>
					{clickedProperties.map((property) => (
						<SwiperSlide key={property.listingId} className="w-full">
							<div className="flex w-full justify-center">
								<MapCard property={property} />
							</div>
						</SwiperSlide>
					))}
				</Swiper>
			)}
		</div>
	);
};

export { SearchMapView };
