import millify from "millify";
import { useNavigate } from "react-router-dom";
import bedIcon from "../../../assets/bedIcon.svg";
import bathIcon from "../../../assets/bathIcon.svg";
import SqftIcon from "../../../assets/sqftIcon.svg";
import { useEffect, useRef, useState } from "react";
import { formatPrice } from "../../../utils/formatPrice";
import { formatDate } from "../../../utils/dateFormatter";
import HomeMapIconUrl from "../../../assets/mapDotIcon.png";
import type { FeatureCollection, Feature, Point } from "geojson";
import ReactMap, { Layer, type MapRef, Source } from "react-map-gl";
import { convertToFormattedAmount } from "../../../utils/convertAmount";
import type { GooglePlace, Listing } from "../../../interfaces/listing";
import { getDistanceFromLatLonInMeters } from "../../../utils/getDistanceFromLatLonInMeters";
import {
	fetchExploreListings,
	type SearchListingInterface,
} from "../../../services/exploreListings";
import {
	homeIconLayerStyle,
	homeLayerStyle,
	nearbyLayerStyle,
} from "./neighbourhoodMapLayerStyles";

interface PropertyDetailsProps {
	icon: string;
	label: string;
	value: string;
}

const PropertyDetails = ({ icon, label, value }: PropertyDetailsProps) => (
	<div className="flex items-center gap-1 text-[#7D7D7D]">
		<img src={icon} alt={label} className="w-4" />
		<div className="text-xs">{value}</div>
	</div>
);

const StarIcon = ({ filled }: { filled: boolean }) => (
	// biome-ignore lint/a11y/noSvgWithoutTitle: <explanation>
	<svg
		xmlns="http://www.w3.org/2000/svg"
		width="16"
		height="16"
		viewBox="0 0 24 24"
		fill={filled ? "#FECD07" : "none"}
		stroke="#FECD07"
		strokeWidth="2"
		strokeLinecap="round"
		strokeLinejoin="round"
	>
		<polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2" />
	</svg>
);

const MAPBOX_TOKEN =
	"pk.eyJ1IjoiYmVhcmNvZGVzODcwIiwiYSI6ImNtMHBmMjVqZzAwZWkycXEwYmcxcHJjMGwifQ.UeHMFKKaRuaTR-NqOPPsnw";

interface NeighborhoodMapProps {
	listing: Listing;
	nearbyType: string;
	googlePlaces: GooglePlace[];
}

const typeMappings: Record<string, string[]> = {
	school: ["school", "secondary_school", "university"],
	grocery: ["supermarket", "drugstore", "pharmacy"],
	nearby: ["restaurant", "cafe"],
	homeSale: [],
};

interface PlaceProperties {
	[name: string]: unknown;
	name: string;
	address: string;
	icon: string;
	distance: string;
	listPrice?: number;
	listingId?: string;
	displayImageUrl?: string;
	numBathrooms?: number;
	numBedrooms?: number;
	sqft?: number;
	rating?: number;
	listDate?: Date;
	downPaymentContribution?: number;
}

const createHomeGeoJson = (
	listing: Listing,
	isHomeIcon: boolean,
): FeatureCollection => {
	return {
		type: "FeatureCollection",
		features: [
			{
				type: "Feature",
				geometry: {
					type: "Point",
					coordinates: [listing.long, listing.lat],
				},
				properties: {
					name: listing.address,
					price: `$${millify(Number.parseInt(listing.listPrice))}`,
					listing: listing,
					icon: isHomeIcon ? "home" : "default",
				},
			},
		],
	};
};

const createGooglePlacesGeoJson = (
	places: GooglePlace[],
): FeatureCollection => {
	const features: Feature[] = places.map((place) => ({
		type: "Feature",
		geometry: {
			type: "Point",
			coordinates: [place.geometry.location.lng, place.geometry.location.lat],
		},
		properties: {
			name: place.name,
			address: place.vicinity,
			icon: place.types[0],
			distance: place.distance,
			rating: place.rating,
		} as PlaceProperties,
	}));

	return {
		type: "FeatureCollection",
		features,
	};
};

const createHomeNearby = (
	homes: SearchListingInterface[],
): FeatureCollection<Point> => {
	return {
		type: "FeatureCollection",
		features: homes
			.filter(
				(home) =>
					home.coordinates?.lat !== undefined &&
					home.coordinates?.long !== undefined,
			)
			.map((home) => ({
				type: "Feature",
				geometry: {
					type: "Point",
					coordinates: [home.coordinates!.long, home.coordinates!.lat],
				},
				properties: {
					address: home.displayAddress || "No Address",
					listPrice: home.listPrice,
					numBathrooms: home.numBathrooms,
					numBedrooms: home.numBedrooms,
					sqft: home.sqft,
					displayImageUrl: home.displayImageUrl,
					listingId: home.listingId,
					downPaymentContribution: home.downPaymentContribution,
					listDate: home.listDate,
				},
			})),
	};
};

const mergeGeoJsonFeatures = (
	homeGeoJson: FeatureCollection,
	newFeatures: FeatureCollection,
	nearbyGeoJson?: FeatureCollection,
): FeatureCollection => {
	return {
		type: "FeatureCollection",
		features: [
			...homeGeoJson.features,
			...newFeatures.features,
			...(nearbyGeoJson?.features || []),
		],
	};
};

const filterGooglePlacesByType = (
	googlePlaces: GooglePlace[],
	type: string,
): GooglePlace[] => {
	const typesToFilter = typeMappings[type];

	return googlePlaces.filter((place) =>
		place.types.some((placeType) => typesToFilter.includes(placeType)),
	);
};

const NeighbourhoodMap = ({
	listing,
	nearbyType,
	googlePlaces,
}: NeighborhoodMapProps) => {
	const [viewState, setViewState] = useState({
		longitude: listing.long,
		latitude: listing.lat,
		zoom: 14,
	});
	const [homesNearby, setHomesNearby] = useState<SearchListingInterface[]>([]);

	const [popupInfo, setPopupInfo] = useState<null | {
		longitude: number;
		latitude: number;
		properties: PlaceProperties;
	}>(null);

	const mapRef = useRef<MapRef | null>(null);

	const isHomeIcon = true;
	const navigate = useNavigate();

	const filteredGooglePlaces = filterGooglePlacesByType(
		googlePlaces,
		nearbyType,
	);
	const googlePlacesGeoJson = createGooglePlacesGeoJson(filteredGooglePlaces);
	const homeGeoJson = createHomeGeoJson(listing, isHomeIcon);
	const nearbyGeoJson =
		nearbyType === "homeSale" ? createHomeNearby(homesNearby) : undefined;

	const mergedGeoJson = mergeGeoJsonFeatures(
		homeGeoJson,
		googlePlacesGeoJson,
		nearbyGeoJson,
	);

	useEffect(() => {
		const fetchNearbyHomes = async () => {
			if (nearbyType !== "homeSale") return;

			const map = mapRef.current?.getMap();
			if (!map) return;

			const { lng, lat } = map.getCenter();
			const bounds = map.getBounds();
			if (!bounds) return;
			const northEast = bounds.getNorthEast();
			const radius = getDistanceFromLatLonInMeters(
				lat,
				lng,
				northEast.lat,
				northEast.lng,
			);

			try {
				const fetchedProperties = await fetchExploreListings(
					"1",
					"20",
					"map",
					undefined,
					lng,
					lat,
					radius,
				);
				setHomesNearby(fetchedProperties);
			} catch (error) {
				console.error("Error fetching nearby homes:", error);
			}
		};

		fetchNearbyHomes();
	}, [nearbyType]);

	useEffect(() => {
		const map = mapRef.current?.getMap();
		if (!map) {
			return;
		}

		const addHomeMapIcon = (retryCount = 0) => {
			const img = new Image();
			img.src = HomeMapIconUrl;
			img.onload = () => {
				if (!map.hasImage("home")) {
					map.addImage("home", img);
				}

				if (!map.getSource("homeSource")) {
					map.addSource("homeSource", {
						type: "geojson",
						data: {
							type: "FeatureCollection",
							features: [
								{
									type: "Feature",
									geometry: {
										type: "Point",
										coordinates: [listing.long, listing.lat],
									},
									properties: {},
								},
							],
						},
					});
				}

				if (!map.getLayer("homeLayer")) {
					map.addLayer({
						id: "homeLayer",
						type: "symbol",
						source: "homeSource",
						layout: {
							"icon-image": "home",
							"icon-size": 0.5,
							"icon-allow-overlap": true,
						},
					});
				}
			};

			img.onerror = (error) => {
				console.error("Error loading home icon:", error);
			};

			if (!map.hasImage("home") && retryCount < 5) {
				setTimeout(() => {
					addHomeMapIcon(retryCount + 1);
				}, 1000);
			}
		};

		const onLoad = () => {
			addHomeMapIcon();
		};

		map.on("load", onLoad);

		return () => {
			map.off("load", onLoad);
		};
	}, [listing]);

	const handleMapClick = (event: mapboxgl.MapMouseEvent) => {
		const features = (event.features as Feature[] | undefined) || [];

		if (features.length > 0) {
			const feature = features[0];
			const properties = feature.properties as PlaceProperties;

			if (feature.geometry.type === "Point") {
				const [longitude, latitude] = feature.geometry.coordinates;
				setPopupInfo({
					longitude,
					latitude,
					properties,
				});
			}
		} else {
			setPopupInfo(null);
		}
	};

	const renderStars = (rating: number) => {
		const fullStars = Math.floor(rating);
		const stars = new Array(5)
			.fill(null)
			.map((_, index) => (
				<StarIcon
					key={`star-${popupInfo?.properties.name}-${index}`}
					filled={index < fullStars}
				/>
			));
		return stars;
	};

	return (
		<div className="relative">
			<ReactMap
				mapboxAccessToken={MAPBOX_TOKEN}
				{...viewState}
				onMove={(evt) => setViewState(evt.viewState)}
				dragPan={true}
				mapStyle={"mapbox://styles/mapbox/streets-v9"}
				ref={mapRef}
				style={{ width: "100%", height: "300px" }}
				interactiveLayerIds={["homeLayer", "homeIconLayer", "nearbyLayer"]}
				onClick={handleMapClick}
			>
				<Source id="my-data" type="geojson" data={mergedGeoJson}>
					<Layer {...homeLayerStyle} />
					<Layer {...homeIconLayerStyle} />
					<Layer {...nearbyLayerStyle} />
				</Source>
			</ReactMap>

			<div className="relative h-full mx-2">
				{popupInfo && !popupInfo.properties.listPrice && (
					<div className="absolute px-3 py-2 bottom-2 w-full bg-white rounded-lg text-sm z-20">
						<div className="w-full space-y-3">
							<div className="flex justify-between items-start w-full">
								<div className="font-bold text-sm">
									{popupInfo.properties.name}
								</div>
								{popupInfo.properties.rating && (
									<div className="flex items-start gap-0.5">
										{renderStars(popupInfo.properties.rating)}
									</div>
								)}
							</div>
							{popupInfo.properties.address && (
								<div className="flex flex-col">
									<div className="text-xs text-[#4F4F4F]">
										{popupInfo.properties.address}
									</div>
									{popupInfo.properties.distance && (
										<div className="text-xs text-[#4F4F4F]">
											<div>{popupInfo.properties.distance} away</div>
										</div>
									)}
								</div>
							)}
						</div>
					</div>
				)}

				{popupInfo?.properties.listPrice && (
					<div
						className="absolute bottom-2 w-full bg-white flex rounded-lg text-sm shadow-lg z-20"
						onClick={() =>
							navigate(`/listing/${popupInfo.properties.listingId}`)
						}
						onKeyDown={() =>
							navigate(`/listing/${popupInfo.properties.listingId}`)
						}
					>
						<img
							src={popupInfo?.properties.displayImageUrl}
							alt={popupInfo?.properties.listingId}
							className="w-full object-cover rounded-l-[10px] col-span-1 max-w-[110px] "
						/>

						<div className="flex-1 flex flex-col px-3 py-1.5 justify-between space-y-0.5">
							<div className="w-full text-xs text-black flex items-center relative">
								<div className="flex-1 text-xl text-black font-bold flex items-center">
									{`$${formatPrice(popupInfo.properties.listPrice / 100)}`}
								</div>
							</div>

							<div className="flex items-center text-z-dark-moss-green text-xs font-bold w-full  gap-3">
								<div className="w-5/10 whitespace-nowrap tracking-tight">
									Zown Contribution
								</div>
								<div className="w-px h-3 bg-gray-400 " />
								<div className="w-5/10 flex justify-between">
									{popupInfo.properties.downPaymentContribution
										? `$${formatPrice(convertToFormattedAmount(popupInfo.properties.downPaymentContribution))}`
										: "N/A"}
								</div>
							</div>
							<div className="text-xs text-black font-light text-wrap overflow-hidden ">
								{popupInfo.properties.address}
							</div>
							<div className="flex-1 flex items-center">
								<div className="text-sm flex items-center gap-3">
									<PropertyDetails
										icon={bedIcon}
										label="Beds"
										value={
											popupInfo.properties.numBedrooms
												? `${popupInfo.properties.numBedrooms}`
												: `${"N/A"}`
										}
									/>
									<PropertyDetails
										icon={bathIcon}
										label="Baths"
										value={
											popupInfo.properties.numBathrooms
												? `${popupInfo.properties.numBathrooms}`
												: `${"N/A"}`
										}
									/>
									<PropertyDetails
										icon={SqftIcon}
										label="Sqft"
										value={
											popupInfo.properties.sqft
												? `${formatPrice(popupInfo.properties.sqft)}`
												: "N/A"
										}
									/>
								</div>
							</div>
							<div className="w-full text-[11px] text-black pt-2 flex items-center">
								<div className="flex-2 text-right  flex items-center justify-end">
									<span className="inline-block w-2.5 h-2.5 bg-[#6ECE35] rounded-full mr-1 tracking-tight" />
									{popupInfo.properties.listDate && (
										<span className="inline-block tracking-tight">
											{formatDate(popupInfo.properties.listDate)}
										</span>
									)}
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export { NeighbourhoodMap };
