/**
 * Formats a given date to display:
 * - "Today"
 * - "{X} days ago"
 * - "{X} days from now"
 * - "{X} months ago"
 * - "{X} months from now"
 * - "{X} years ago"
 * - "{X} years from now"
 * - "yyyy-mm-dd" (when format is explicitly set to "date")
 *
 * @param dateInput - The date string in ISO format (e.g., "2024-12-17T00:00:00.000") or a Date object.
 * @param format - Optional format parameter ("relative" | "date"). Defaults to "relative".
 * @returns The formatted date string.
 */
export function formatDate(
  dateInput: string | Date,
  format: "relative" | "date" = "relative"
): string {
  let dateInToronto: Date;

  if (typeof dateInput === "string") {
    const rawDate = dateInput.split("T")[0];
    const [year, month, day] = rawDate.split("-").map(Number);
    dateInToronto = new Date(year, month - 1, day); // Create a date from the string
  } else if (dateInput instanceof Date) {
    dateInToronto = new Date(
      dateInput.getFullYear(),
      dateInput.getMonth(),
      dateInput.getDate()
    ); // Strip time
  } else {
    throw new Error("Invalid date input. Must be a string or Date object.");
  }

  if (format === "date") {
    // Return the date in "yyyy-mm-dd" format
    const yyyy = dateInToronto.getFullYear();
    const mm = String(dateInToronto.getMonth() + 1).padStart(2, "0");
    const dd = String(dateInToronto.getDate()).padStart(2, "0");
    return `${yyyy}-${mm}-${dd}`;
  }

  // Get today's date for comparison
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Reset time to the start of the day

  const timeDifferenceInSeconds =
    (today.getTime() - dateInToronto.getTime()) / 1000;
  const dayDifference = Math.floor(timeDifferenceInSeconds / (60 * 60 * 24));

  if (dayDifference === 0) {
    return "Today";
  }

  // Calculate accurate year and month differences
  const yearsDifference = today.getFullYear() - dateInToronto.getFullYear();
  let monthsDifference =
    yearsDifference * 12 + today.getMonth() - dateInToronto.getMonth();

  // Adjust for dates within the same year/month
  if (
    today.getMonth() < dateInToronto.getMonth() ||
    (today.getMonth() === dateInToronto.getMonth() &&
      today.getDate() < dateInToronto.getDate())
  ) {
    monthsDifference -= 1;
  }

  if (yearsDifference !== 0 && Math.abs(dayDifference) > 364) {
    return yearsDifference === 1
      ? "1 year ago"
      : `${Math.abs(yearsDifference)} years ${
          yearsDifference > 0 ? "ago" : "from now"
        }`;
  }

  if (monthsDifference !== 0 && Math.abs(dayDifference) > 30) {
    return monthsDifference === 1
      ? "1 month ago"
      : `${Math.abs(monthsDifference)} months ${
          monthsDifference > 0 ? "ago" : "from now"
        }`;
  }

  return dayDifference > 0
    ? dayDifference === 1
      ? "1 day ago"
      : `${dayDifference} days ago`
    : `${Math.abs(dayDifference)} days from now`;
}
