import { formatDate } from "../../utils/dateFormatter";
import NotificationHouseIcon from "../../assets/Notification/notifiactionHouseIcon.svg";

interface NotificationItemProps {
	title: string;
	body: string;
	createdAt: Date;
}

interface NotificationSectionProps {
	sectionTitle: string;
	items: NotificationItemProps[];
}

const NotificationItem = ({
	title,
	body,
	createdAt,
}: NotificationItemProps) => {
	return (
		<div className="flex items-center justify-between w-full gap-1 bg-white p-4 rounded-[15px] shadow-md drop-shadow-md">
			<div className="flex items-center gap-4 w-full">
				<div>
					<img
						src={NotificationHouseIcon}
						className="w-[40px] h-[40px] rounded-full"
						alt={title}
					/>
				</div>
				<div className="w-full space-y-1">
					<div className="flex justify-between w-full">
						<div className="text-sm font-semibold text-[#6541F8] w-full">
							{title}
						</div>
						<div className="text-sm font-semibold text-end w-full flex justify-end">
							{formatDate(createdAt)}
						</div>
					</div>
					<div className="text-sm font-medium w-full">{body}</div>
				</div>
			</div>
		</div>
	);
};

export const NotificationSection = ({
	sectionTitle,
	items,
}: NotificationSectionProps) => {
	return (
		<div className="w-full space-y-4">
			<div className="font-bold">{sectionTitle}</div>
			{items.map((item) => (
				<NotificationItem
					key={item.title}
					createdAt={item.createdAt}
					title={item.title}
					body={item.body}
				/>
			))}
		</div>
	);
};
