import clsx from "clsx";
import { useEffect, useState } from "react";
import { Button } from "../components/common";
import { useAuth } from "../context/authProvider";
import { initiateGuestAuth } from "../services/auth";
import { useAuthStore } from "../stores/useAuthStore";
import { PhoneValidator } from "../utils/phoneValidator";
import type { MainProfile } from "../interfaces/mainProfile";
import { useUserProfileStore } from "../stores/profileStore";
import { ProgressBar } from "../components/common/progressBar";
import { capitalizeFirstLetter } from "../utils/capitalizeLetter";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { convertToNumberAndMultiplyBy100 } from "../utils/convertAmount";
import { updateAccountData, updateFinancesData } from "../services/account";
import {
	useAdvertisementStep,
	useAdvertisementUserStore,
} from "../stores/advertisementFlowStore";

const PROGRESS_STEP_MAP: { [key: number]: number } = {
	1: 17,
	2: 34,
	3: 48,
	4: 65,
	5: 78,
	6: 100,
};
const INDICES_STEPS = Object.keys(PROGRESS_STEP_MAP).map(Number);

const AdvertisementFlow = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const [progressBarHeight, setProgressBarHeight] = useState(0);
	const [footerHeight, setFooterHeight] = useState(0);
	const { setUserProfile } = useUserProfileStore();
	const { setAuthEmail } = useAuthStore();

	const { advertisementCurrentStep, setAdvertisementStep } =
		useAdvertisementStep();
	const {
		firstName,
		lastName,
		phoneNumber,
		email,
		income,
		savings,
		debt,
		validateIncome,
		validateSavings,
		validateDebt,
		validateNameFields,
		validateContactFields,
		setIncomeError,
		setSavingsError,
		setDebtError,
	} = useAdvertisementUserStore();
	const showProgressBar = INDICES_STEPS.includes(advertisementCurrentStep);
	const number = PROGRESS_STEP_MAP[advertisementCurrentStep] || 0;
	const dynamicPaddingTop = `${progressBarHeight}px`;
	const [buttonDisabled, setButtonDisabled] = useState(false);
	const dynamicPaddingBottom = `${footerHeight}px`;
	const { setAuthenticated } = useAuth();

	const handleNextStep = async () => {
		const accountPayload: Partial<MainProfile> = {};

		if (firstName) {
			accountPayload.firstName = capitalizeFirstLetter(firstName);
		}
		if (lastName) {
			accountPayload.lastName = capitalizeFirstLetter(lastName);
		}
		if (phoneNumber) {
			let formattedPhoneNumber: string | undefined;
			try {
				if (PhoneValidator.isValid(phoneNumber, "CA")) {
					formattedPhoneNumber = PhoneValidator.formatE164(phoneNumber, "CA");
				} else if (PhoneValidator.isValid(phoneNumber, "US")) {
					formattedPhoneNumber = PhoneValidator.formatE164(phoneNumber, "US");
				} else {
					throw new Error("Invalid phone number format");
				}
				accountPayload.phoneNumber = formattedPhoneNumber;
			} catch (error) {
				console.error("Error formatting phone number:", error);
				accountPayload.phoneNumber = "";
			}
		}
		switch (location.pathname) {
			case "/login/about-yourself-view": {
				if (validateNameFields()) {
					setButtonDisabled(true);

					window
						._sendEvent("ZownAppBuyerForm", {
							Step: 2,
						})
						.catch((error: unknown) => {
							console.error(error);
						})
						.finally(() => {
							setButtonDisabled(false);

							navigate("/login/how-reach-you-view");
							setAdvertisementStep(1);
						});
				}

				break;
			}
			case "/login/how-reach-you-view": {
				if (validateContactFields()) {
					try {
						setButtonDisabled(true);
						const initiateGuestResponse = await initiateGuestAuth(email);

						if (initiateGuestResponse.status === 201) {
							setAuthEmail(email);
							setButtonDisabled(false);
							window
								._sendEvent("ZownAppBuyerForm", {
									Step: 3,
								})
								.catch((error: unknown) => {
									console.error(error);
								})
								.finally(() => {
									navigate("/login/verify-view", {
										state: { origin: "verifyGuest" },
									});
								});
						} else {
							if (Object.keys(accountPayload).length > 0) {
								const accountResponse = await updateAccountData(accountPayload);
								setUserProfile(accountResponse);
							}

							setAuthenticated(true);
							setButtonDisabled(false);
							window
								._sendEvent("ZownAppBuyerForm", {
									Step: 3,
								})
								.catch((error: unknown) => {
									console.error(error);
								})
								.finally(() => {
									setAdvertisementStep(2);
									navigate("/income-view");
								});
						}
					} catch (error) {
						console.error("Error updating income data:", error);
						setButtonDisabled(false);
					}
				}

				break;
			}
			case "/income-view": {
				if (validateIncome()) {
					const financesIncomePayload = {
						totalIncome: {
							amount: convertToNumberAndMultiplyBy100(income),
							currency: "CAD",
						},
					};
					try {
						setButtonDisabled(true);
						const financesResponse = await updateFinancesData(
							financesIncomePayload,
						);
						setUserProfile(financesResponse);
						setButtonDisabled(false);
					} catch (error) {
						console.error("Error updating income data:", error);
						setButtonDisabled(false);
					}

					window
						._sendEvent("ZownAppBuyerForm", {
							Step: 4,
						})
						.catch((error: unknown) => {
							console.error(error);
						})
						.finally(() => {
							setAdvertisementStep(3);
							navigate("/saving-view");
						});
				}

				break;
			}
			case "/saving-view": {
				if (validateSavings()) {
					const financesSavingPayload = {
						totalSavings: {
							amount: convertToNumberAndMultiplyBy100(savings),
							currency: "CAD",
						},
					};
					try {
						setButtonDisabled(true);
						const financesResponse = await updateFinancesData(
							financesSavingPayload,
						);
						setUserProfile(financesResponse);
						setButtonDisabled(false);
					} catch (error) {
						console.error("Error updating savings data:", error);
						setButtonDisabled(false);
					}

					window
						._sendEvent("ZownAppBuyerForm", {
							Step: 5,
						})
						.catch((error: unknown) => {
							console.error(error);
						})
						.finally(() => {
							setAdvertisementStep(4);
							navigate("/debt-view");
						});
				}

				break;
			}
			case "/debt-view": {
				if (validateDebt()) {
					const financesDebtPayload = {
						totalMonthlyDebts: {
							amount: convertToNumberAndMultiplyBy100(debt),
							currency: "CAD",
						},
					};
					try {
						setButtonDisabled(true);
						const financesResponse =
							await updateFinancesData(financesDebtPayload);
						setUserProfile(financesResponse);
						setButtonDisabled(false);
					} catch (error) {
						console.error("Error updating debt data:", error);
						setButtonDisabled(false);
					}

					window
						._sendEvent("ZownAppBuyerForm", {
							Step: 6,
						})
						.catch((error: unknown) => {
							console.error(error);
						})
						.finally(() => {
							setAdvertisementStep(5);
							navigate("/max-affordability-view");
						});
				}

				break;
			}
			case "/max-affordability-view": {
				window
					._sendEvent("ZownAppBuyerForm", {
						Step: 7,
					})
					.catch((error: unknown) => {
						console.error(error);
					})
					.finally(() => {
						setAdvertisementStep(6);
						navigate("/affordability-view");
					});

				break;
			}

			case "/affordability-view": {
				window
					._sendEvent("ZownAppBuyerForm", {
						Step: 8,
					})
					.catch((error: unknown) => {
						console.error(error);
					})
					.finally(() => {
						setAdvertisementStep(7);
						console.log("Navigate to the next page");
						navigate("/");
					});

				break;
			}

			default:
				break;
		}
	};

	const handlePreviousStep = () => {
		switch (location.pathname) {
			case "/affordability-view":
				setAdvertisementStep(5);
				break;
			case "/max-affordability-view":
				setAdvertisementStep(4);
				break;
			case "/debt-view":
				setDebtError("");
				setAdvertisementStep(3);
				break;
			case "/saving-view":
				setSavingsError("");
				setAdvertisementStep(2);

				break;
			case "/income-view":
				setIncomeError("");
				setAdvertisementStep(1);
				break;
			case "/login/how-reach-you-view":
				break;
			case "/login/about-yourself-view":
				break;
			default:
				break;
		}
		navigate(-1);
	};

	useEffect(() => {
		const progressBarEl = document.getElementById("progress-bar");
		const footerEl = document.getElementById("footer");

		if (progressBarEl) {
			setProgressBarHeight(progressBarEl.offsetHeight);
		}
		if (footerEl) {
			setFooterHeight(footerEl.offsetHeight);
		}
	}, []);

	return (
		<div
			className={clsx(" overflow-auto absolute  inset-0")}
			style={{
				paddingTop: dynamicPaddingTop,
				paddingBottom: dynamicPaddingBottom,
			}}
		>
			<div
				id="progress-bar"
				className={`fixed ${
					window.bridge ? "top-bridge-inset-top" : "top-0"
				}  bg-white h-20 w-full z-10`}
			>
				<ProgressBar totalSteps={number} />
			</div>

			<div
				className={clsx(
					"relative flex justify-start h-full overflow-auto items-center pt-bridge-inset-top pb-bridge-inset-bottom",

					!showProgressBar && "h-full",
				)}
			>
				<Outlet />
			</div>
			<div
				id="footer"
				className={`fixed bottom-0 right-0 left-0 flex items-center ${
					window.bridge ? "pb-bridge-inset-bottom pt-3" : "py-4"
				} justify-between px-4 bg-white border-t border-[#F2F2F2] w-full`}
			>
				<Button
					onClick={handlePreviousStep}
					disable={buttonDisabled}
					variant="primary"
					customClassName="font-bold  text-lg bg-white"
					height="lg"
					isOutlined={true}
					width="120px"
					radius="60px"
				>
					{advertisementCurrentStep === 4 ? "No" : "Back"}
				</Button>

				<Button
					onClick={handleNextStep}
					variant="primary"
					customClassName="font-bold text-lg disabled:bg-[#D9D9D9] min-w-[120px] shadow-md"
					height="lg"
					width="fit"
					radius="60px"
					disable={buttonDisabled}
				>
					{advertisementCurrentStep === 6 ? "Yes" : "Next"}
				</Button>
			</div>
		</div>
	);
};

export { AdvertisementFlow };
