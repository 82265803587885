import { LoadingCircle } from "../common";
import { useNavigate } from "react-router-dom";
import bedIcon from "../../assets/bedIcon.svg";
import SqftIcon from "../../assets/sqftIcon.svg";
import bathIcon from "../../assets/bathIcon.svg";
import { HeartIcon } from "../../assets/heartIcon";
import { SortOptions } from "../common/sortOptions";
import { reactions } from "../../services/reactions";
import { formatPrice } from "../../utils/formatPrice";
import { formatDate } from "../../utils/dateFormatter";
import { fetchAccountData } from "../../services/account";
import { useUserProfileStore } from "../../stores/profileStore";
import { convertToFormattedAmount } from "../../utils/convertAmount";
import type { CartProperties } from "../../interfaces/cartProperties";
import { type KeyboardEvent, useState, useEffect, useRef } from "react";
import NoMorePropertiesIcon from "../../assets/noMorePropertiesIcon.svg";

interface PropertyDetailsProps {
	icon: string;
	label: string;
	value: string;
}

const PropertyDetails = ({ icon, label, value }: PropertyDetailsProps) => (
	<div className="flex items-center gap-1 text-[#7D7D7D]">
		<img src={icon} alt={label} className="" />
		<div className="text-sm">{value}</div>
	</div>
);

const LikedHomesView = () => {
	const navigate = useNavigate();
	const [likedProperties, setLikedProperties] = useState<CartProperties[]>([]);
	const [showOptions, setShowOptions] = useState(false);
	const optionsRef = useRef<HTMLDivElement | null>(null);
	const [selectedOption, setSelectedOption] = useState("Default");
	const [loading, setLoading] = useState(false);
	const { userProfile, setUserProfile } = useUserProfileStore();

	useEffect(() => {
		setLikedProperties(userProfile?.likedHomes || []);
	}, [userProfile]);

	useEffect(() => {
		const getProfile = async () => {
			try {
				setLoading(true);
				const accountData = await fetchAccountData();

				if (accountData) {
					setUserProfile(accountData);
				}

				setLoading(false);
			} catch (error) {
				console.error("Failed to getProfile", error);
			}
		};

		getProfile();
	}, [setUserProfile]);

	const toggleOptions = () => {
		setShowOptions(!showOptions);
	};

	useEffect(() => {
		if (showOptions && optionsRef.current) {
			const optionsRect = optionsRef.current.getBoundingClientRect();
			const isOffScreen = optionsRect.right > window.innerWidth;

			if (isOffScreen) {
				optionsRef.current.style.left = `-${
					optionsRect.right - window.innerWidth + 20
				}px`;
			}
		}
	}, [showOptions]);

	const sortByNewest = (a: CartProperties, b: CartProperties) =>
		new Date(b.listDate || 0).getTime() - new Date(a.listDate || 0).getTime();

	const sortByOldest = (a: CartProperties, b: CartProperties) =>
		new Date(a.listDate || 0).getTime() - new Date(b.listDate || 0).getTime();
	const sortByLowestPrice = (a: CartProperties, b: CartProperties) =>
		a.listPrice.amount - b.listPrice.amount;
	const sortByHighestPrice = (a: CartProperties, b: CartProperties) =>
		b.listPrice.amount - a.listPrice.amount;

	const sortedProperties = [...likedProperties].sort((a, b) => {
		switch (selectedOption) {
			case "Newest":
				return sortByNewest(a, b);
			case "Oldest":
				return sortByOldest(a, b);
			case "Price Low to High":
				return sortByLowestPrice(a, b);
			case "Price High to Low":
				return sortByHighestPrice(a, b);
			default:
				return 0;
		}
	});

	const handleDislike = async (zlid: string) => {
		await reactions(zlid, "REMOVE_SAVED_LISTING");

		const updatedProperties = likedProperties.filter(
			(property) => property.listingId !== zlid,
		);
		setLikedProperties(updatedProperties);
	};

	return (
		<div
			className={`flex flex-col items-center ${
				loading || likedProperties.length === 0 ? "h-full" : ""
			}  text-black space-y-6 px-2 pt-3 pb-6`}
		>
			{loading && (
				<div className="flex items-center justify-center h-full">
					<LoadingCircle />
				</div>
			)}
			{!loading && likedProperties.length === 0 && (
				<div className="flex items-center text-center justify-center flex-col h-full w-full px-4 space-y-8">
					<div className="space-y-3">
						<div className="font-bold text-3xl">Oops !</div>
						<div className="text-2xl">
							no liked <span className="text-[#FF5757]">properties</span>
						</div>
					</div>
					<img src={NoMorePropertiesIcon} alt="No More Properties" />
					<div className="w-full space-y-1">
						<div className="text-xl">add properties to your likes</div>
						<button
							type="button"
							className="bg-z-dark-moss-green text-white font-bold text-xl py-3.5 w-full rounded-full"
							onClick={() => navigate("/")}
						>
							Explore homes
						</button>
					</div>
				</div>
			)}

			{!loading && likedProperties.length !== 0 && (
				<div className="w-full flex flex-col gap-4">
					<div className="w-full flex items-end justify-end">
						<SortOptions
							showOptions={showOptions}
							optionsRef={optionsRef}
							onToggleOptions={toggleOptions}
							selectedOption={selectedOption}
							setSelectedOption={setSelectedOption}
						/>
					</div>
					{sortedProperties.map((property) => (
						<div
							key={property.listingId}
							className="bg-white rounded-[10px] shadow-lg flex flex-col"
						>
							<div className="flex">
								<div
									className="w-[150px]"
									onClick={() => navigate(`/listing/${property.listingId}`)}
									onKeyDown={() => navigate(`/listing/${property.listingId}`)}
								>
									<img
										src={property.displayImageUrl}
										alt={property.address.address1}
										className="w-full h-[120px] object-cover rounded-tl-[10px]"
									/>
								</div>
								<div className="flex flex-col p-2 justify-between w-full">
									<div className="text-xl font-semibold w-full flex items-center justify-between">
										<div
											className="w-full"
											onClick={() => navigate(`/listing/${property.listingId}`)}
											onKeyDown={() =>
												navigate(`/listing/${property.listingId}`)
											}
										>
											$
											{formatPrice(
												convertToFormattedAmount(property.listPrice.amount),
											)}
										</div>
										<button
											type="button"
											className="z-10"
											onClick={() => handleDislike(property.listingId)}
										>
											<HeartIcon fill="#FF5757" />
										</button>
									</div>
									<div
										onClick={() => navigate(`/listing/${property.listingId}`)}
										onKeyDown={() => navigate(`/listing/${property.listingId}`)}
										className="flex items-center gap-0.5 text-z-dark-moss-green text-[11.5px] font-extrabold w-full justify-between"
									>
										<div className="w-fit">Down Payment Contribution</div>
										<div className="flex justify-end">
											$
											{formatPrice(
												convertToFormattedAmount(
													property.downPaymentGiven.amount,
												),
											)}
										</div>
									</div>
									<div>
										<div className="text-sm font-light">
											{property.address.address1}, {property.address.city}
										</div>
									</div>
									<div
										onClick={() => navigate(`/listing/${property.listingId}`)}
										onKeyDown={() => navigate(`/listing/${property.listingId}`)}
									>
										<div className="text-sm flex items-center gap-3">
											<PropertyDetails
												icon={bedIcon}
												label="Beds"
												value={
													property.bedrooms
														? `${property.bedrooms}`
														: `${"N/A"}`
												}
											/>
											<PropertyDetails
												icon={bathIcon}
												label="Baths"
												value={
													property.bathrooms
														? `${property.bathrooms}`
														: `${"N/A"}`
												}
											/>
											<PropertyDetails
												icon={SqftIcon}
												label="Sqft"
												value={
													property.squareFeet
														? `${formatPrice(property.squareFeet)}`
														: "N/A"
												}
											/>
										</div>
									</div>
								</div>
							</div>
							<div
								onClick={() => navigate(`/listing/${property.listingId}`)}
								onKeyDown={() => navigate(`/listing/${property.listingId}`)}
								className="w-full px-4 py-2 text-sm rounded-b-[10px] flex items-center gap-1.5 font-medium bg-[#F8F8F8]"
							>
								<div className="h-[14px] w-[14px] bg-[#6ECE35] rounded-full" />
								{formatDate(property.listDate)}
							</div>
						</div>
					))}
				</div>
			)}
		</div>
	);
};

export { LikedHomesView };
