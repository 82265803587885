import { FaSearch } from "react-icons/fa";
import { ListCard } from "./Card/listCard";
import { SearchField } from "./searchField";
import { LoadingCircle } from "../common/loading";
import { SortOptions } from "../common/sortOptions";
import { useAuth } from "../../context/authProvider";
import { SearchMapView } from "./SearchMap/searchMap";
import { fetchAccountData } from "../../services/account";
import { useMapStore } from "../../stores/mapCordinations";
import { useNavbarStore } from "../../stores/navBarStatus";
import { NoListingsCard } from "./NoListings/noListingsCard";
import { useFilterPanelStore } from "../../stores/filterStore";
import { useUserProfileStore } from "../../stores/profileStore";
import { useMapFiltersStore } from "../../stores/filterMapStore";
import { usePaginationStore } from "../../services/paginationStore";
import { mapPropertyTypeForPost } from "../common/FilterPanel/filterPanel";
import { convertToNumberAndMultiplyBy100 } from "../../utils/convertAmount";
import type { BuyerMatchingPreferencesPropertyTypeEnum } from "../../interfaces/buyerMatchingPreferences";
import {
	useFilteredPropertiesStore,
	useListingStore,
} from "../../stores/exploreListings";
import {
	useCallback,
	useEffect,
	useRef,
	useState,
	type TouchEvent,
	type MouseEvent,
	useMemo,
} from "react";
import {
	fetchExploreListings,
	parseBasement,
	parseDaysOnMarket,
	parseGarage,
	parseNumKitchen,
	parseStyle,
	transformFilterValues,
} from "../../services/exploreListings";

import "swiper/css";
import "./exploreListingsViewStyles.css";

const BuyerExploreListingsView = () => {
	const { isWebview } = useAuth();

	const [showSortOptions, setShowSortOptions] = useState(false);
	const [selectedSortOption, setSelectedSortOption] = useState("Default");
	const [currentFetchedPage, setCurrentFetchedPage] = useState(1);

	const [mapOpen, setMapOpen] = useState<boolean>(false);
	const { filterPanelVisible } = useFilterPanelStore();
	const { showNavbar, hideNavbar } = useNavbarStore();
	const { mapLatitude, mapLongitude, mapZoom } = useMapStore();
	const { setListings } = useListingStore();
	const optionsRef = useRef<HTMLDivElement | null>(null);
	const {
		currentPage,
		setCurrentPage,
		setPageStepList,
		pageStepList,
		setPageStep,
		pageStep,
	} = usePaginationStore();
	const { filteredProperties, setFilteredProperties, setLoading, loading } =
		useFilteredPropertiesStore();
	const {
		minPrice,
		maxPrice,
		beds,
		baths,
		dens,
		parking,
		propertyType,
		numberOfKitchens,
		garageType,
		basement,
		style,
		daysOnMarket,
		reloadMapCities,
		setReloadMapCities,
		setSortFilter,
		sortFilter,
	} = useMapFiltersStore();

	const { setUserProfile } = useUserProfileStore();

	const propertiesPerPage = 20;

	console.log("loading", loading);

	const totalPages = Math.ceil(filteredProperties.length / propertiesPerPage);

	// Calculate the indices for slicing properties
	const startIndex = (currentPage - 1) * propertiesPerPage;
	const endIndex = startIndex + propertiesPerPage;

	// Slice the properties for the current page
	const propertiesToDisplay = useMemo(
		() => filteredProperties.slice(startIndex, endIndex),
		[filteredProperties, startIndex, endIndex],
	);

	// Pagination range for buttons
	const paginationRange = useMemo(() => {
		const maxVisiblePages = 5;
		const start = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
		const end = Math.min(totalPages, start + maxVisiblePages - 1);
		return Array.from({ length: end - start + 1 }, (_, i) => start + i);
	}, [currentPage, totalPages]);

	const goToPreviousPage = () => {
		if (currentPage > 1) {
			setCurrentPage(currentPage - 1);
		}
	};
	const fetchProperties = useCallback(
		async (page: string) => {
			if (!mapOpen) {
				const fetchedProperties = await fetchExploreListings(
					page,
					sortFilter.limit,
					sortFilter.sortBy,
					sortFilter.sortOrder,
					mapLongitude ?? undefined,
					mapLatitude ?? undefined,
					mapZoom ?? undefined,
					undefined,

					{
						numberOfBedrooms:
							beds.length > 0 ? transformFilterValues(beds) : null,
						numberOfBathrooms:
							baths.length > 0 ? transformFilterValues(baths) : null,

						numberOfParkingSpaces:
							parking.length > 0 ? transformFilterValues(parking) : null,

						dens: dens,
						priceRange: [
							convertToNumberAndMultiplyBy100(minPrice.toString()),
							convertToNumberAndMultiplyBy100(maxPrice.toString()),
						],
						propertyType:
							propertyType.length > 0
								? propertyType
										.map(mapPropertyTypeForPost)
										.filter(
											(pt): pt is BuyerMatchingPreferencesPropertyTypeEnum =>
												pt !== null,
										)
								: null,

						numKitchens: parseNumKitchen(numberOfKitchens),
						garage: parseGarage(garageType),
						daysOnMarket: parseDaysOnMarket(daysOnMarket),
						style: parseStyle(style),
						basement: parseBasement(basement),
						selectedCities: null,
					},
				);

				setListings(fetchedProperties);
				setFilteredProperties(fetchedProperties);
			}
		},
		[
			sortFilter,

			mapOpen,
			setListings,
			beds,
			baths,
			dens,
			parking,
			minPrice,
			maxPrice,
			propertyType,
			numberOfKitchens,
			garageType,
			daysOnMarket,
			style,
			basement,
			mapLatitude,
			mapLongitude,
			mapZoom,
			setFilteredProperties,
		],
	);

	const goToNextPage = async () => {
		if (currentPage < totalPages) {
			setCurrentPage(currentPage + 1);
		} else if (filteredProperties.length === 250) {
			const nextPage = (currentFetchedPage + 1).toString();
			setLoading(true);
			try {
				await fetchProperties(nextPage);
			} catch (error) {
				console.error("Error fetching properties:", error);
			} finally {
				setLoading(false);
			}
		}
	};

	// useEffect(() => {
	// 	if (filteredProperties.length === 0) {
	// 		setLoading(true)
	// 		fetchProperties(sortFilter.page || "1");
	// 		setLoading(false)
	// 	}
	// }, [fetchProperties, filteredProperties.length, sortFilter.page]);

	const goToPage = (page: number) => {
		setCurrentPage(page);
	};

	useEffect(() => {
		if (filteredProperties.length > 0) {
			setCurrentPage(1);
		}
	}, [filteredProperties, setCurrentPage]);

	useEffect(() => {
		setPageStepList(1);
	}, [setPageStepList]);

	const toggleMap = () => {
		setMapOpen(!mapOpen);
	};

	const toggleOptions = () => {
		setShowSortOptions(!showSortOptions);
	};

	const [startY, setStartY] = useState<number | null>(null);

	const handleTouchStart = (event: TouchEvent<HTMLDivElement>) => {
		setStartY(event.touches[0].clientY);
	};
	useEffect(() => {
		if (mapOpen) {
			setSelectedSortOption("Default");
		}
	}, [mapOpen]);
	const handleTouchMove = (event: TouchEvent<HTMLDivElement>) => {
		if (startY !== null) {
			const currentY = event.touches[0].clientY;
			if (startY - currentY > 50) {
				setMapOpen(false);
				setStartY(null);
			}
		}
	};

	const handleMouseMove = (event: MouseEvent) => {
		if (startY !== null) {
			const currentY = event.clientY;
			if (startY - currentY > 50) {
				setMapOpen(false);
				setStartY(null);
			}
		}
	};

	const handleMouseDown = (event: MouseEvent) => {
		setStartY(event.clientY);
	};

	useEffect(() => {
		const fetchNewProperties = async () => {
			setLoading(true);
			if (!isWebview) {
				const accountData = await fetchAccountData();

				if (accountData) {
					setUserProfile(accountData);
				}
			}
			await fetchProperties(sortFilter.page || "1");
			setLoading(false);
		};

		if (reloadMapCities && !mapOpen && !filterPanelVisible) {
			setFilteredProperties([]);
			setMapOpen(true);
			fetchNewProperties();

			setReloadMapCities(false);
		}
	}, [
		reloadMapCities,
		sortFilter.page,
		setReloadMapCities,
		setFilteredProperties,
		fetchProperties,
		setUserProfile,
		filterPanelVisible,
		setLoading,
		mapOpen,
		isWebview,
	]);

	useEffect(() => {
		const initializePage = async () => {
			setLoading(true);
			if (!isWebview) {
				const accountData = await fetchAccountData();
				if (accountData) {
					setUserProfile(accountData);
				}
			}
			await fetchProperties(sortFilter.limit || "1");

			setLoading(false);
		};
		if (!mapOpen && !filterPanelVisible) {
			initializePage();
		}
	}, [
		fetchProperties,
		setUserProfile,
		mapOpen,
		filterPanelVisible,
		isWebview,
		setLoading,
		sortFilter.limit,
	]);

	useEffect(() => {
		mapOpen && !filterPanelVisible ? hideNavbar() : showNavbar();
	}, [mapOpen, hideNavbar, showNavbar, filterPanelVisible]);

	useEffect(() => {
		if (showSortOptions && optionsRef.current) {
			const optionsRect = optionsRef.current.getBoundingClientRect();
			const isOffScreen = optionsRect.right > window.innerWidth;

			if (isOffScreen) {
				optionsRef.current.style.left = `-${
					optionsRect.right - window.innerWidth + 20
				}px`;
			}
		}
	}, [showSortOptions]);

	useEffect(() => {
		const defaultPage = "1";
		const defaultLimit = "250";

		switch (true) {
			case selectedSortOption === "Default": {
				setFilteredProperties([]);
				setSortFilter({
					page: defaultPage,
					limit: defaultLimit,
					sortBy: "date",
					sortOrder: "desc",
				});
				break;
			}
			case selectedSortOption === "Newest": {
				setFilteredProperties([]);
				setSortFilter({
					page: defaultPage,
					limit: defaultLimit,
					sortBy: "date",
					sortOrder: "desc",
				});
				break;
			}
			case selectedSortOption === "Oldest": {
				setFilteredProperties([]);
				setSortFilter({
					page: defaultPage,
					limit: defaultLimit,
					sortBy: "date",
					sortOrder: "asc",
				});
				break;
			}
			case selectedSortOption === "Price Low to High": {
				setFilteredProperties([]);
				setSortFilter({
					page: defaultPage,
					limit: defaultLimit,
					sortBy: "price",
					sortOrder: "asc",
				});
				break;
			}
			case selectedSortOption === "Price High to Low": {
				setFilteredProperties([]);
				setSortFilter({
					page: defaultPage,
					limit: defaultLimit,
					sortBy: "price",
					sortOrder: "desc",
				});
				break;
			}
			default:
				break;
		}
	}, [selectedSortOption, setFilteredProperties, setSortFilter]);

	return (
		<div
			className={`relative ${mapOpen && "overflow-hidden h-[100vh]"} ${!mapOpen && !isWebview && "pb-28"} ${isWebview && "pb-6"}`}
		>
			{mapOpen && (
				<div className="h-[100vh]">
					<SearchMapView />
				</div>
			)}

			<div
				className={`green-header ${
					mapOpen ? "-translate-y-[120vh]" : "translate-y-0"
				}`}
			/>

			<div
				className={`search-field-container flex items-center ${
					window.bridge ? "pt-bridge-inset-top" : "pt-10"
				} z-20`}
			>

				<SearchField />
			</div>

			<div
				onClick={toggleMap}
				onKeyDown={toggleMap}
				onTouchStart={handleTouchStart}
				onTouchMove={handleTouchMove}
				onMouseDown={handleMouseDown}
				onMouseMove={handleMouseMove}
				className={`show-map-tab-map ${
					mapOpen
						? "fixed w-full bottom-0 h-24 overflow-hidden"
						: "absolute -translate-y-[3vh] show-map-tab-height "
				}`}
			>
				{mapOpen && (
					<hr className="w-1/4 absolute top-3 border-t-4 border-gray-300" />
				)}
				<div className="flex items-center">
					{!mapOpen && <FaSearch className="text-black mr-2" />}
					<span className="text-black font-[550]">
						{mapOpen
							? `${filteredProperties.length >= 250 ? `${filteredProperties.length}+` : filteredProperties.length} Homes In Search`
							: "Show Map"}
					</span>
				</div>
			</div>

			{!mapOpen && (
				<div className="listings-container translate-y-0">
					<div
						className={`w-full flex flex-col gap-4 relative ${
							filterPanelVisible ? "overflow-hidden" : "overflow-scroll"
						}`}
					>
						<div className="flex items-center justify-between p-4 shadow-sm">
							<span className="text-black font-semibold">
								{filteredProperties.length > 0 && (
									<>
										{filteredProperties.length >= 250
											? `${filteredProperties.length}+`
											: filteredProperties.length}{" "}
										Listings
									</>
								)}
							</span>
							<SortOptions
								showOptions={showSortOptions}
								optionsRef={optionsRef}
								onToggleOptions={toggleOptions}
								selectedOption={selectedSortOption}
								setSelectedOption={setSelectedSortOption}
							/>
						</div>
						{loading ? (
							<div className="flex items-center justify-center h-full w-full">
								<LoadingCircle />
							</div>
						) : propertiesToDisplay.length > 0 && !loading ? (
							propertiesToDisplay.map((property) => (
								<div key={property.listingId} className="px-2">
									<ListCard property={property} />
								</div>
							))
						) : (
							<NoListingsCard />
						)}
						{!loading && filteredProperties.length > 0 && (
							<div className="flex justify-center items-center mt-6 border border-gray-300 rounded-md w-fit mx-auto">
								<button
									type="button"
									onClick={goToPreviousPage}
									disabled={currentPage === 1}
									className={`px-4 py-2 text-sm rounded text-gray-500 ${
										currentPage === 1 ? "opacity-50" : ""
									}`}
								>
									Previous
								</button>
								{paginationRange.map((page) => (
									<button
										key={page}
										type="button"
										onClick={() => goToPage(page)}
										className={`px-4 py-2 border-x text-sm border-gray-300 ${
											page === currentPage
												? "bg-z-dark-moss-green text-white font-bold"
												: "bg-white hover:bg-gray-100 text-gray-500"
										}`}
									>
										{page}
									</button>
								))}
								<button
									type="button"
									onClick={goToNextPage}
									className={`px-4 py-2 text-sm rounded text-gray-500 ${
										currentPage === totalPages &&
										filteredProperties.length < 250
											? "opacity-50"
											: ""
									}`}
								>
									Next
								</button>
							</div>
						)}
					</div>
				</div>
			)}
		</div>
	);
};

export { BuyerExploreListingsView };
