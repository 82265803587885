import { debounce } from "lodash";
import { LoadingCircle } from "../common";
import { useNavigate } from "react-router-dom";
import bedIcon from "../../assets/bedIcon.svg";
import bathIcon from "../../assets/bathIcon.svg";
import SqftIcon from "../../assets/sqftIcon.svg";
import { useAuth } from "../../context/authProvider";
import { reactions } from "../../services/reactions";
import { formatPrice } from "../../utils/formatPrice";
import { formatDate } from "../../utils/dateFormatter";
import { SelectedCitiesEnum } from "../../selectedCities";
import { fetchAccountData } from "../../services/account";
import { useUserProfileStore } from "../../stores/profileStore";
import { useMapFiltersStore } from "../../stores/filterMapStore";
import { useCallback, useEffect, useRef, useState } from "react";
import CitiesSearchIcon from "../../assets/citiesSearchIcon.svg";
import GrayArrowDownIcon from "../../assets/grayArrowDownIcon.svg";
import ListingSearchIcon from "../../assets/listingSearchIcon.svg";
import { convertToFormattedAmount } from "../../utils/convertAmount";
import { FaPlus, FaMinus, FaSearch, FaHeart, FaRegHeart } from "react-icons/fa";
import {
	fetchExploreListings,
	type SearchListingInterface,
} from "../../services/exploreListings";

interface CityChipProps {
	label: string;
	searchTerm?: string;
	onClick: (label: string) => void;
}

interface PropertyDetailsProps {
	icon: string;
	label: string;
	value: string;
}

const PropertyDetails = ({ icon, label, value }: PropertyDetailsProps) => (
	<div className="flex items-center gap-1 text-[#7D7D7D]">
		<img src={icon} alt={label} className="" />
		<div className="text-sm">{value}</div>
	</div>
);

const SearchSelector = ({ label, searchTerm, onClick }: CityChipProps) => {
	const highlightMatchingText = (text: string, search: string | undefined) => {
		const searchTerm = typeof search === "string" ? search : "";

		if (!searchTerm.trim()) {
			return text;
		}

		const escapedSearch = searchTerm.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
		const regex = new RegExp(`(${escapedSearch})`, "gi");

		return text.split(regex).join("");
	};

	return (
		<div
			className="flex m-2 border-b overflow-hidden font-medium py-3 text-sm cursor-pointer"
			onClick={() => onClick(label)}
			onKeyDown={() => onClick(label)}
		>
			<div className="flex items-center pr-2">
				<FaPlus className="text-sm" />
			</div>
			<div className="flex-1 flex items-center text-black">
				{highlightMatchingText(label, searchTerm)}
			</div>
		</div>
	);
};

const SelectedCity = ({ label, onClick }: CityChipProps) => {
	return (
		<div
			className="flex my-2 rounded-md bg-gray-100 border overflow-hidden"
			onClick={() => {
				onClick(label);
			}}
			onKeyDown={() => {
				onClick(label);
			}}
		>
			<div className="flex-1 py-3 px-4 text-sm flex items-center text-black font-bold">
				{label}
			</div>
			<div className="flex items-center pr-2">
				<FaMinus className="text-[#32302F]" />
			</div>
		</div>
	);
};

const testSearch = Object.values(SelectedCitiesEnum);

const SearchView = () => {
	const [searchActive, setSearchActive] = useState<boolean>(false);
	const [searchTerm, setSearchTerm] = useState<string>("");
	const [filteredCities, setFilteredCities] = useState<string[]>([]);
	const [showAll, setShowAll] = useState<boolean>(false);
	const [loading, setLoading] = useState<boolean>(false);
	const [properties, setProperties] = useState<SearchListingInterface[]>([]);

	const { userProfile, setUserProfile } = useUserProfileStore();
	const { isWebview } = useAuth();

	const navigate = useNavigate();

	const {
		setMapSelectedCities,
		removeMapSelectedCity,
		mapSelectedCities,
		setReloadMapCities,
	} = useMapFiltersStore();

	const inputRef = useRef<HTMLInputElement>(null);

	const fetchCities = useCallback(
		debounce((term: string) => {
			const results = testSearch.filter((city) =>
				city.toLowerCase().includes(term.toLowerCase()),
			);
			setFilteredCities(results);
		}, 300),
		[],
	);

	useEffect(() => {
		if (inputRef.current) {
			inputRef.current.focus();
		}
	}, []);

	const handleAddCity = useCallback(
		(label: string) => {
			if (!label || typeof label !== "string") {
				console.warn("Invalid label:", label);
				return;
			}

			setMapSelectedCities(label);
		},
		[setMapSelectedCities],
	);

	const handleRemoveCity = (label: string) => {
		removeMapSelectedCity(label);
	};

	const displayedCities = showAll ? filteredCities : filteredCities.slice(0, 5);

	const fetchProperties = useCallback(
		debounce(async (search: string) => {
			if (!search.trim()) {
				setProperties([]);
				return;
			}
			console.log("search", search);
			const formattedSearch = encodeURIComponent(search.trim());
			console.log("formattedSearch", formattedSearch);

			setLoading(true);

			try {
				const fetchedProperties = await fetchExploreListings(
					"1",
					"100",
					"search",
					undefined,
					undefined,
					undefined,
					undefined,
					search.trim(),
					undefined,
				);
				console.log("fetchedProperties", fetchedProperties);

				setProperties(fetchedProperties);
			} catch (error) {
				console.error("Error fetching properties:", error);
			} finally {
				setLoading(false);
			}
		}, 500),
		[],
	);

	useEffect(() => {
		fetchCities(searchTerm);
		fetchProperties(searchTerm);
	}, [searchTerm, fetchCities, fetchProperties]);

	const handleLikeProperty = async (listingId: string) => {
		try {
			if (!isWebview) {
				const isLiked = userProfile?.likedHomes?.some(
					(likedHome) => likedHome.listingId === listingId,
				);

				if (isLiked) {
					await reactions(listingId, "REMOVE_SAVED_LISTING");
				} else {
					await reactions(listingId, "SAVE_LISTING");
				}

				const accountData = await fetchAccountData();

				if (accountData) {
					setUserProfile(accountData);
				}
			}
		} catch (error) {
			console.error("Error handling like/unlike property:", error);
		}
	};

	return (
		<div className="bg-white flex flex-col h-full relative">
			<div className="flex flex-col w-full justify-start pt-2 space-y-2 px-4">
				<div className="items-center w-full space-y-2">
					<div className="flex rounded-full border-2 overflow-hidden">
						<div className="flex items-center pl-4">
							<FaSearch className="text-gray-500 text-lg" />
						</div>

						<input
							ref={inputRef}
							type="text"
							placeholder="Search address, neighbourhood or city"
							className="flex-1 py-5 px-4 text-sm text-black"
							onFocus={() => setSearchActive(true)}
							onChange={(e) => setSearchTerm(e.target.value)}
							value={searchTerm}
						/>
					</div>
				</div>
			</div>
			<div className="overflow-y-auto">
				{!searchActive && (
					<div className="px-4">
						<div className="flex flex-col w-full py-6">
							{mapSelectedCities && mapSelectedCities.length > 0 && (
								<span className="text-black font-bold text-md">
									Selected City
								</span>
							)}
							{mapSelectedCities &&
								mapSelectedCities.length > 0 &&
								mapSelectedCities.map((label, index) => (
									<SelectedCity
										key={`${label}-${index}`}
										label={label}
										onClick={handleRemoveCity}
									/>
								))}
						</div>
					</div>
				)}

				{searchActive && (
					<div className="pt-6">
						{displayedCities.length > 0 && (
							<div className="bg-[#F6F6F6] text-[#32302F] px-6 font-bold text-sm py-2 flex items-center gap-3">
								<img src={CitiesSearchIcon} alt="" />
								Cities
							</div>
						)}
						{displayedCities.map((label) => (
							<SearchSelector
								key={label}
								label={label}
								searchTerm={searchTerm}
								onClick={(label) => {
									handleAddCity(label);
									setReloadMapCities(true);
									navigate("/explore");
								}}
							/>
						))}
						{filteredCities.length > 5 && (
							<button
								type="button"
								className="text-[#7D7D7D] flex items-center gap-2 justify-center w-full h-10 font-bold text-sm"
								onClick={() => setShowAll(!showAll)}
							>
								{showAll ? "Show Less" : "Show More"}
								<img
									src={GrayArrowDownIcon}
									alt=""
									className={`transition-transform duration-300 ${
										showAll ? "rotate-180" : ""
									}`}
								/>
							</button>
						)}
						{!loading && properties && properties.length > 0 && (
							<div className="bg-[#F6F6F6] text-[#32302F] px-6 font-bold text-sm py-2 flex items-center gap-3">
								<img src={ListingSearchIcon} alt="" />
								Listings
							</div>
						)}
						{loading ? (
							<div className="text-center py-4">
								<LoadingCircle />
							</div>
						) : (
							properties.map((property) => (
								<div
									key={property.listingId}
									className="bg-white  w-full flex border-b border-b-[#E6E6E6] py-3"
								>
									<div
										onClick={() => navigate(`/listing/${property.listingId}`)}
										onKeyDown={() => navigate(`/listing/${property.listingId}`)}
									>
										<img
											src={property.images?.[0] || ""}
											alt={property.listingId}
											className="w-full h-full object-cover  col-span-1 max-w-[110px] "
										/>
									</div>
									<div className="flex-1 flex flex-col px-3 py-4 justify-between">
										<div className="w-full text-xs text-black flex items-center relative">
											<div
												className="flex-1 text-2xl text-black font-bold flex items-center"
												onClick={() =>
													navigate(`/listing/${property.listingId}`)
												}
												onKeyDown={() =>
													navigate(`/listing/${property.listingId}`)
												}
											>
												{`$${formatPrice(property.listPrice / 100)}`}
											</div>
											{!isWebview && (
												<button
													type="button"
													className="flex-2 absolute right-0 w-12 h-12 flex items-center justify-end "
													onClick={() => handleLikeProperty(property.listingId)}
												>
													{userProfile?.likedHomes?.some(
														(likedHome) =>
															likedHome.listingId === property.listingId,
													) ? (
														<FaHeart className="text-red-700 text-xl" />
													) : (
														<FaRegHeart className="text-red-700 text-xl" />
													)}
												</button>
											)}
										</div>

										<div
											onClick={() => navigate(`/listing/${property.listingId}`)}
											onKeyDown={() =>
												navigate(`/listing/${property.listingId}`)
											}
											className="flex items-center text-z-dark-moss-green text-xs font-bold w-full justify-between gap-1"
										>
											<div className="w-5/10 whitespace-nowrap tracking-tight">
												Down Payment Contribution
											</div>
											<div className="w-px h-3 bg-gray-400 " />
											<div className="w-5/10 pl-1 flex justify-between">
												{property.downPaymentContribution
													? `$${formatPrice(convertToFormattedAmount(property.downPaymentContribution))}`
													: "N/A"}
											</div>
										</div>
										<div
											onClick={() => navigate(`/listing/${property.listingId}`)}
											onKeyDown={() =>
												navigate(`/listing/${property.listingId}`)
											}
											className="text-sm text-black font-light whitespace-nowrap overflow-hidden text-ellipsis max-w-[200px]"
										>
											{property.displayAddress}
										</div>

										<div
											onClick={() => navigate(`/listing/${property.listingId}`)}
											onKeyDown={() =>
												navigate(`/listing/${property.listingId}`)
											}
											className="w-full text-xs text-black pt-2 flex items-center"
										>
											<div className="flex-1 flex items-center">
												<div className="text-sm flex items-center gap-3">
													<PropertyDetails
														icon={bedIcon}
														label="Beds"
														value={
															property.numBedrooms
																? `${property.numBedrooms}`
																: `${"N/A"}`
														}
													/>
													<PropertyDetails
														icon={bathIcon}
														label="Baths"
														value={
															property.numBathrooms
																? `${property.numBathrooms}`
																: `${"N/A"}`
														}
													/>
													<PropertyDetails
														icon={SqftIcon}
														label="Sqft"
														value={
															property.sqft
																? `${formatPrice(property.sqft)}`
																: "N/A"
														}
													/>
												</div>
											</div>
											<div className="flex-2 text-right  flex items-center justify-end">
												<span className="inline-block w-2.5 h-2.5 bg-[#6ECE35] rounded-full mr-1 tracking-tight" />
												<span className="inline-block tracking-tight">
													{formatDate(property.listDate)}
												</span>
											</div>
										</div>
									</div>
								</div>
							))
						)}
					</div>
				)}
			</div>
		</div>
	);
};

export { SearchView };
