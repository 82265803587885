import { useRef, useEffect } from "react";
import XbuttonIcon from "../assets/xButtonIcon.svg";
import { useShowLoginModalStore } from "../stores/loginModalStore";
import { openLink } from "../services/openLink";

const LoginModal = () => {
	const modalRef = useRef<HTMLDivElement | null>(null);
	const { setShowLoginModal } = useShowLoginModalStore();

	useEffect(() => {
		const handleOutsideClick = (event: MouseEvent) => {
			if (
				modalRef.current &&
				!modalRef.current.contains(event.target as Node)
			) {
				setShowLoginModal(false);
			}
		};

		document.addEventListener("mousedown", handleOutsideClick);

		return () => {
			document.removeEventListener("mousedown", handleOutsideClick);
		};
	}, [setShowLoginModal]);

	return (
		<div className="fixed inset-0 z-50 flex items-center justify-center px-2">
			<div className="absolute inset-0 bg-white bg-opacity-70 backdrop-blur-sm" />
			<div
				ref={modalRef}
				className="relative w-full max-w-md py-6 px-6 bg-white shadow-md rounded-[20px] space-y-4 z-10  flex flex-col items-center justify-center"
			>
				<button
					type="button"
					className="right-4 absolute w-[46px] h-[46px] rounded-full flex items-start justify-end top-4"
					onClick={() => setShowLoginModal(false)}
				>
					<img src={XbuttonIcon} alt="Close" />
				</button>
				<div className="text-center space-y-4">
					<h2 className="text-xl font-bold text-gray-800">
						Register to See More Details
					</h2>
					<p className="text-gray-600">
						To view all the details for this home, download our app.
					</p>
				</div>

				<button
					type="button"
					className="bg-z-dark-moss-green w-full text-white px-6 py-3.5 font-bold rounded-full "
					onClick={() => openLink("https://zown.ca/app")}
				>
					Download App
				</button>
			</div>
		</div>
	);
};

export { LoginModal };
